import {ListItemIcon, ListItemText, MenuList} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as DomainsIcon} from '../../assets/icons/domains.svg';
import {ReactComponent as ServersIcon} from '../../assets/icons/servers.svg';
import {ReactComponent as SSHIcon} from '../../assets/icons/ssh.svg';
// import {ReactComponent as TeamIcon} from '../../assets/icons/team.svg';
import SidebarMenuItem from '../../components/SidebarMenuItem';
import Paths from '../../routes/Paths';
import {MenuItemDataType} from './types';

export const SystemMenu: React.FC = () => {
  const {t} = useTranslation();

  const items: MenuItemDataType[] = [
    {
      path: Paths.Servers,
      title: t('sidebar_system_menu_item_servers'),
      icon: <ServersIcon />,
      childPaths: [Paths.ServerInfo],
    },
    {
      path: Paths.Domains,
      title: t('sidebar_system_menu_item_domains'),
      icon: <DomainsIcon />,
    },
    {
      path: Paths.SshKeys,
      title: t('sidebar_system_menu_item_ssh_keys'),
      icon: <SSHIcon />,
    },
    // {
    //   path: Paths.Team,
    //   title: t('sidebar_system_menu_item_team'),
    //   icon: <TeamIcon />,
    // },
  ];

  return (
    <MenuList sx={(theme) => ({marginTop: theme.spacing(5)})}>
      {items.map(({path, title, icon, childPaths}) => (
        <SidebarMenuItem key={path} path={path} childPaths={childPaths}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText>{title}</ListItemText>
        </SidebarMenuItem>
      ))}
    </MenuList>
  );
};

export default SystemMenu;
