import {TableCell} from '@mui/material';
import React from 'react';
import {ca2types} from '../../../api/proto';
import {formatDateTimeTimestamp} from '../../../utils/format/format';
import {BALANCE_OPERATION_TITLES} from '../OperationTypeFilter';
import BalanceOperationAmount from './BalanceOperationAmount';
import {BALANCE_OPERATIONS_TABLE_COLUMNS, BalanceOperationColumnType} from './BalanceOperationsTable';

interface IProps {
  billingOperation: ca2types.IBalanceOperation;
}

export const BalanceOperationTableRow: React.FC<IProps> = ({billingOperation}) => {
  const getTableCellComponent = (columnId: BalanceOperationColumnType) => {
    switch (columnId) {
      case BalanceOperationColumnType.Amount:
        return <BalanceOperationAmount amountFormatted={billingOperation.amount?.formatted} />;
      case BalanceOperationColumnType.Date:
        return formatDateTimeTimestamp(billingOperation.createdAt ? billingOperation.createdAt.toNumber() * 1000 : 0);
      case BalanceOperationColumnType.Type:
        return billingOperation.type ? BALANCE_OPERATION_TITLES[billingOperation.type] : null;
      case BalanceOperationColumnType.Description:
        return billingOperation.description;
      case BalanceOperationColumnType.Id:
        return billingOperation.id?.toNumber();
    }
  };

  return (
    <React.Fragment>
      {BALANCE_OPERATIONS_TABLE_COLUMNS.map((column) => (
        <TableCell
          align={column.align}
          key={column.id}
          sx={{
            borderLeft: 'none',
            borderRight: 'none',
            width: column.width,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {getTableCellComponent(column.id)}
        </TableCell>
      ))}
    </React.Fragment>
  );
};

export default BalanceOperationTableRow;
