import {Provider} from 'mobx-react';
import 'react-toastify/dist/ReactToastify.css';
import AppContainer from './AppContainer';
import './assets/scss/index.scss';
import AppLoader from './components/AppLoader';
import ThemeSelector from './components/ThemeWrappers';
import ToastContainer from './components/ToastContainer';
import Modals from './modals/Modals';
import Routes from './routes';
import store from './stores/AppStore';
import AppTheme from './theme';

export const App = () => {
  return (
    <Provider store={store}>
      <AppTheme>
        <AppContainer>
          <ThemeSelector>
            <AppLoader>
              <Routes />
              <ToastContainer />
              <Modals />
            </AppLoader>
          </ThemeSelector>
        </AppContainer>
      </AppTheme>
    </Provider>
  );
};

export default App;
