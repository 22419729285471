import {LoadingButton} from '@mui/lab';
import {Button, DialogActions, DialogContent} from '@mui/material';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useStore} from '../stores/AppStore';
import {ModalType} from '../stores/ModalsStore';
import BaseModal from './BaseModal';

const ModalConfirm: React.FC = observer(() => {
  const {modals} = useStore();
  const {t} = useTranslation();
  const [loading, setLoading] = React.useState(false);

  const modalState = modals.getModalState(ModalType.CONFIRM);

  const handleClose = () => {
    modals.close(ModalType.CONFIRM);
  };

  const handleSubmit = async () => {
    setLoading(true);
    await modalState.modalItem?.trigger('submit');
    setLoading(false);
    handleClose();
  };

  const handleCancelClick = () => {
    if (modalState.data?.callbacks?.cancel) {
      modalState.modalItem?.trigger('cancel');
    } else {
      handleClose();
    }
  };

  return (
    <BaseModal
      maxWidth={modalState.data?.maxWidth || 'xs'}
      fullWidth
      type={ModalType.CONFIRM}
      title={modalState.data?.headerText || ''}
    >
      <DialogContent className="p-6 pt-7 body1-regular custom-scroll-y">
        {modalState.data?.title}

        {modalState.data?.content}
      </DialogContent>

      <DialogActions>
        <Button
          size="large"
          variant="outlined"
          color={modalState.data?.cancelButtonColor || 'secondary'}
          onClick={handleCancelClick}
        >
          {modalState.data?.cancelButtonText ? modalState.data.cancelButtonText : t('action_button_cancel')}
        </Button>
        <LoadingButton
          size="large"
          variant="contained"
          color={modalState.data?.submitButtonColor || 'error'}
          loading={loading}
          onClick={handleSubmit}
        >
          {modalState.data?.submitButtonText ? modalState.data.submitButtonText : t('action_button_submit')}
        </LoadingButton>
      </DialogActions>
    </BaseModal>
  );
});

export default ModalConfirm;
