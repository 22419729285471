import React from 'react';
import {ReactComponent as LogoImage} from '../../assets/image-icons/logo.svg';
import {APP_NAME} from '../../constants';

const AuthHead: React.FC = () => {
  return (
    <div className="auth-head">
      <LogoImage className="auth-head__logo" />
      <span className="auth-head__app-name">{APP_NAME}</span>
    </div>
  );
};

export default AuthHead;
