import {DialogContent} from '@mui/material';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';
import {ca2types} from '../api/proto';
import SshKeyItem from '../components/SshKeyItem';
import {useStore} from '../stores/AppStore';
import {ModalType} from '../stores/ModalsStore';
import BaseModal from './BaseModal';

const ModalSelectSshKey: React.FC = observer(() => {
  const {t} = useTranslation();
  const {modals, sshKeysStore} = useStore();
  const {data, modalItem} = modals.getModalState(ModalType.SELECT_SSH_KEY);

  const isSelected = (id?: number | null) => {
    return !!(id && data?.selectedSshKeysIds?.includes(id));
  };

  const handleClick = (sshKey: ca2types.ISSHKey) => {
    data?.callbacks?.['onSelectSshKey']({sshKey});
    modals.close(modalItem?.type);
  };

  return (
    <BaseModal type={ModalType.SELECT_SSH_KEY} title={t('modal_select_ssh_key_title')}>
      <DialogContent sx={(theme) => ({padding: theme.spacing(1)})}>
        {sshKeysStore.list.map((sshKey) => (
          <SshKeyItem key={sshKey.id} sshKey={sshKey} selected={isSelected(sshKey.id)} onClick={handleClick} />
        ))}
      </DialogContent>
    </BaseModal>
  );
});

export default ModalSelectSshKey;
