import {action, makeObservable, observable} from 'mobx';
import {matchPath} from 'react-router';
import Paths, {InstanceRouteParams} from '../../routes/Paths';
import browserHistory from '../../routes/browserHistory';
import logger from '../../utils/logger';
import {AppStore} from '../AppStore';
import Instance from './Instance';

export class InstanceView {
  constructor(public app: AppStore) {
    makeObservable(this);
  }

  @observable activeInstance: Instance | null = null;

  @action setActiveInstance = (instance: Instance | null) => {
    this.activeInstance = instance;
  };

  public openInstanceByParams = (params: InstanceRouteParams) => {
    const instanceId = Number(params.instanceId);

    if (!isNaN(instanceId)) {
      const foundInstance = this.app.instancesStore.findInstanceById(instanceId);

      if (foundInstance) {
        this.setActiveInstance(foundInstance);
        return;
      }
    }

    logger.error(`%c---> InstanceView:openInstanceByID: [${instanceId}] - instance not found`, 'color: red');

    browserHistory.push(Paths.Servers);
    return;
  };

  deleteActiveInstance = async () => {
    await this.activeInstance?.delete();
    const matchServerInfo = matchPath(Paths.ServerInfo, browserHistory.location.pathname);

    if (matchServerInfo) {
      browserHistory.push(Paths.Servers);
    }

    this.reset();
  };

  @action reset = () => {
    this.activeInstance = null;
  };
}
