import {Box, Button, styled, useTheme} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ca2billing} from '../../api/proto';
import {ReactComponent as CheckIcon} from '../../assets/icons/check-xl.svg';
import PriceFormatter from '../../utils/priceFormatter';
import CurrencyIcon from '../CurrencyIcon';
import TextField from '../UI/TextField';
import Typography from '../UI/Typography';
import OrderIdTitle from './OrderIdTitle';

const BorderBox = styled(Box)(({theme}) => ({
  border: `1px solid ${theme.palette.border.primary}`,
  borderRadius: '8px',
  padding: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

interface IProps {
  topUpBalanceResponse: ca2billing.ITopUpBalanceResponse;
  onSwitchToPaymentConfirmed(): void;
}

export const AwaitingPaymentForm: React.FC<IProps> = (props) => {
  const theme = useTheme();
  const {t} = useTranslation();

  const handleMarkAsPaid = () => {
    props.onSwitchToPaymentConfirmed();
  };

  const amountFormatter = new PriceFormatter(props.topUpBalanceResponse.amount);

  return (
    <>
      <BorderBox>
        <Typography variant="h4" gutterBottom>
          {t('component_payment_viewer_awaiting_payment_title')}
        </Typography>

        <Typography variant="body1" gutterBottom>
          {t('component_payment_viewer_awaiting_payment_description')}
        </Typography>
      </BorderBox>

      <TextField
        fullWidth
        value={props.topUpBalanceResponse.amountToPay}
        label={t('component_payment_viewer_amount_label')}
        formControlProps={{sx: {marginBottom: 0}}}
        endAdornment={
          <div style={{color: theme.palette.body.placeholder}}>{props.topUpBalanceResponse.currencyCode}</div>
        }
        startAdornment={
          props.topUpBalanceResponse.currencyCode ? (
            <CurrencyIcon code={props.topUpBalanceResponse.currencyCode} style={{marginRight: theme.spacing(2)}} />
          ) : null
        }
      />
      <Typography variant="body3" sx={{marginBottom: theme.spacing(4)}}>
        = {amountFormatter.formatted}
      </Typography>

      <Button
        fullWidth
        variant="contained"
        size="large"
        color="success"
        sx={{marginBottom: theme.spacing(2)}}
        onClick={handleMarkAsPaid}
        startIcon={<CheckIcon style={{fill: theme.palette.body.tint}} />}
      >
        {t('component_payment_viewer_mark_as_paid_button')}
      </Button>

      <Button fullWidth variant="outlined" size="large">
        {t('component_payment_viewer_contact_support_button')}
      </Button>

      <OrderIdTitle orderId={props.topUpBalanceResponse.foreignId} />
    </>
  );
};

export default AwaitingPaymentForm;
