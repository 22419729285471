import {Box, Button, styled, useTheme} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ca2types} from '../../api/proto';
import Typography from '../UI/Typography';
import TldLIst, {makeTldItem, TldItem} from './TldList';

const Container = styled(Box)(({theme}) => ({
  padding: theme.spacing(4),
  '&:not(:last-child)': {
    borderBottom: `1px solid ${theme.palette.border.primary}`,
  },
}));

interface IProps {
  group: ca2types.ITLDGroup;
  selectedTldNames: string[];
  onToggleTld?(tld: string);
  onSelectGroup?(tldGroupId: string);
  onDeselectGroup?(tldGroupId: string);
}

export const TldGroup: React.FC<IProps> = ({group, selectedTldNames, onToggleTld, onDeselectGroup, onSelectGroup}) => {
  const {t} = useTranslation();
  const theme = useTheme();

  const tldItems = React.useMemo<TldItem[]>(() => {
    return group.tldsNames?.map((tldName) => makeTldItem(tldName, selectedTldNames.includes(tldName))) || [];
  }, [group, selectedTldNames]);

  const handleToggleTld = (tld: string) => {
    onToggleTld?.(tld);
  };

  const handleDeselectGroup = () => {
    group.id && onDeselectGroup?.(group.id);
  };

  const handleSelectGroup = () => {
    group.id && onSelectGroup?.(group.id);
  };

  const isEverySelected = tldItems.every(({checked}) => checked);
  const isSomeSelected = tldItems.some(({checked}) => checked);

  if (!group.tldsNames) {
    return null;
  }

  return (
    <Container>
      <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <Typography variant="body1" color="body.placeholder" sx={{marginBottom: theme.spacing(3)}}>
          {group.title}
        </Typography>

        <Box>
          {!isEverySelected ? (
            <Button variant="text" sx={{color: theme.palette.brand.primary}} onClick={handleSelectGroup}>
              {t('component_tld_group_select_all_button')}
            </Button>
          ) : null}
          {isSomeSelected ? (
            <Button variant="text" sx={{color: theme.palette.brand.primary}} onClick={handleDeselectGroup}>
              {t('component_tld_group_deselect_all_button')}
            </Button>
          ) : null}
        </Box>
      </Box>

      <TldLIst tldItems={tldItems} onChangeItem={handleToggleTld} />
    </Container>
  );
};

export default TldGroup;
