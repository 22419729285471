import {LoadingButton} from '@mui/lab';
import {Button, DialogActions, DialogContent} from '@mui/material';
import {useFormik} from 'formik';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import {getApiAddSshKeyError} from '../api/getApiError';
import {ca2ssh} from '../api/proto';
import TextField from '../components/UI/TextField';
import {useStore} from '../stores/AppStore';
import {ModalType} from '../stores/ModalsStore';
import extractNameFromSshKey from '../utils/extractNameFromSshKey';
import BaseModal from './BaseModal';

const ModalAddSshKey: React.FC = observer(() => {
  const {modals, sshKeysStore, notification} = useStore();
  const {t} = useTranslation();
  const [loading, setLoading] = React.useState(false);

  const modalState = modals.getModalState(ModalType.ADD_SSH_KEY);

  const formik = useFormik({
    initialValues: {
      name: '',
      publicKey: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t('modal_add_ssh_key_name_required')),
      publicKey: Yup.string().required(t('modal_add_ssh_key_public_key_required')),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      handleSubmit(values);
    },
  });

  const handleClose = () => {
    modals.close(ModalType.ADD_SSH_KEY);
  };

  const handleSubmit = async ({name, publicKey}) => {
    setLoading(true);
    const {res, error} = await sshKeysStore.addSshKey({name, publicKey});

    if (error) {
      notification.error(error.message);
    }

    if (res) {
      const {errors} = res;

      if (errors?.length) {
        errors.forEach((error) => {
          const errorMessage = getApiAddSshKeyError(error);

          if (error === ca2ssh.AddSSHKeyError.ASE_INVALID_PUBLIC_KEY_ERROR) {
            formik.setFieldError('publicKey', errorMessage);
          } else {
            notification.error(errorMessage);
          }
        });

        setLoading(false);
        return;
      }

      notification.success(t('modal_add_ssh_key_success_notification'));
      modals.close(modalState.modalItem?.type);
      setLoading(false);
    }
  };

  const handleChangePublicKey = (e: React.ChangeEvent<HTMLInputElement>) => {
    const keyName = extractNameFromSshKey(e.target.value.trim());

    if (!formik.values.name.length && keyName) {
      formik.setFieldValue('name', keyName);
    }

    formik.setFieldValue('publicKey', e.target.value);
  };

  return (
    <BaseModal maxWidth="sm" fullWidth type={ModalType.ADD_SSH_KEY} title={t('modal_add_ssh_key_title')}>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            name="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            error={!!formik.errors.name}
            helperText={formik.errors.name}
            placeholder={t('modal_add_ssh_key_name_placeholder')}
            label={t('modal_add_ssh_key_name_label')}
            fullWidth
          />
          <TextField
            name="publicKey"
            onChange={handleChangePublicKey}
            onBlur={formik.handleBlur}
            value={formik.values.publicKey}
            error={!!formik.errors.publicKey}
            helperText={formik.errors.publicKey}
            placeholder={t('modal_add_ssh_key_key_placeholder')}
            label={t('modal_add_ssh_key_key_label')}
            fullWidth
            multiline
            autoFocus
            minRows={8}
          />
        </form>
      </DialogContent>

      <DialogActions>
        <Button size="large" variant="outlined" onClick={handleClose}>
          {t('action_button_cancel')}
        </Button>
        <LoadingButton
          size="large"
          variant="contained"
          onClick={() => {
            formik.submitForm();
          }}
          loading={loading}
        >
          {t('action_button_create')}
        </LoadingButton>
      </DialogActions>
    </BaseModal>
  );
});

export default ModalAddSshKey;
