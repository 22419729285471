import i18n from '../i18n';
import {ca2, ca2auth, ca2billing, ca2domains, ca2instances, ca2ssh} from './proto';

const {t} = i18n;

export function getApiServerError(err: ca2.ServerError): string {
  const errors = {
    [ca2.ServerError.SE_EXPIRED_TOKEN_ERROR]: t('api_error_server_expired_token'),
    [ca2.ServerError.SE_INTERNAL_SERVER_ERROR]: t('api_error_server_internal'),
    [ca2.ServerError.SE_INVALID_TOKEN_ERROR]: t('api_error_server_invalid_token'),
    [ca2.ServerError.SE_INVALID_TRX_ERROR]: t('api_error_server_invalid_trx'),
    [ca2.ServerError.SE_TOKEN_REQUIRED_ERROR]: t('api_error_server_token_required'),
    [ca2.ServerError.SE_TRX_REQUIRED_ERROR]: t('api_error_server_trx_required'),
    [ca2.ServerError.SE_UNKNOWN_ERROR]: t('api_error_server_unknown'),
  };

  return errors[err] || t('api_server_unknown');
}

export function getApiAuthStartError(err: ca2auth.StartError): string {
  const errors = {
    [ca2auth.StartError.SE_UNKNOWN_ERROR]: t('api_error_auth_start_unknown_error'),
    [ca2auth.StartError.SE_INVALID_EMAIL_ERROR]: t('api_error_auth_start_invalid_email'),
    [ca2auth.StartError.SE_INVALID_RECAPTCHA_ERROR]: t('api_error_auth_start_invalid_recaptcha'),
    [ca2auth.StartError.SE_INVALID_REFERRAL_CODE_ERROR]: t('api_error_auth_start_invalid_referral_code'),
  };

  return errors[err] || t('api_auth_start_unknown_error');
}

export function getApiAuthEnterCodeError(err: ca2auth.EnterCodeError): string {
  const errors = {
    [ca2auth.EnterCodeError.ECE_UNKNOWN_ERROR]: t('api_error_auth_enter_code_unknown_error'),
    [ca2auth.EnterCodeError.ECE_INVALID_EMAIL_ERROR]: t('api_error_auth_enter_code_invalid_email'),
    [ca2auth.EnterCodeError.ECE_INVALID_CODE_ERROR]: t('api_error_auth_enter_code_invalid_code'),
    [ca2auth.EnterCodeError.ECE_CODE_EXPIRED_ERROR]: t('api_error_auth_enter_code_expired_code'),
    [ca2auth.EnterCodeError.ECE_USER_NOT_FOUND_ERROR]: t('api_error_auth_enter_code_user_not_found'),
    [ca2auth.EnterCodeError.ECE_TOO_MANY_ATTEMPTS_ERROR]: t('api_error_auth_enter_code_too_many_attempts'),
  };

  return errors[err] || t('api_error_auth_enter_code_unknown_error');
}

export function getApiAuthEnterPasswordError(err: ca2auth.EnterPasswordError): string {
  const errors = {
    [ca2auth.EnterPasswordError.EPE_UNKNOWN_ERROR]: t('api_error_auth_enter_password_unknown_error'),
    [ca2auth.EnterPasswordError.EPE_INVALID_EMAIL_ERROR]: t('api_error_auth_enter_password_invalid_email'),
    [ca2auth.EnterPasswordError.EPE_INVALID_PASSWORD_ERROR]: t('api_error_auth_enter_password_invalid_password'),
  };

  return errors[err] || t('api_error_auth_enter_code_unknown_error');
}

export function getApiAddSshKeyError(err: ca2ssh.AddSSHKeyError): string {
  const errors = {
    [ca2ssh.AddSSHKeyError.ASE_EMPTY_NAME_ERROR]: t('api_error_add_ssh_key_empty_name'),
    [ca2ssh.AddSSHKeyError.ASE_INVALID_PUBLIC_KEY_ERROR]: t('api_error_add_ssh_key_invalid_public_key'),
    [ca2ssh.AddSSHKeyError.ASE_UNKNOWN_ERROR]: t('api_error_add_ssh_key_unknown_error'),
  };

  return errors[err] || t('api_error_add_ssh_key_unknown_error');
}

export function getApiUpdateSshKeyError(err: ca2ssh.UpdateSSHKeyError): string {
  const errors = {
    [ca2ssh.UpdateSSHKeyError.USE_EMPTY_NAME_ERROR]: t('api_error_add_ssh_key_empty_name'),
    [ca2ssh.UpdateSSHKeyError.USE_INVALID_PUBLIC_KEY_ERROR]: t('api_error_add_ssh_key_invalid_public_key'),
    [ca2ssh.UpdateSSHKeyError.USE_INVALID_ID_ERROR]: t('api_error_add_ssh_key_invalid_id'),
    [ca2ssh.UpdateSSHKeyError.USE_UNKNOWN_ERROR]: t('api_error_add_ssh_key_unknown_error'),
  };

  return errors[err] || t('api_error_add_ssh_key_unknown_error');
}

export function getApiDeleteSshKeyError(err: ca2ssh.DeleteSSHKeyError): string {
  const errors = {
    [ca2ssh.DeleteSSHKeyError.DSE_INVALID_ID_ERROR]: t('api_error_add_ssh_key_empty_name'),
    [ca2ssh.DeleteSSHKeyError.DSE_KEY_USED_ERROR]: t('api_error_delete_ssh_key_used_error'),
    [ca2ssh.DeleteSSHKeyError.DSE_UNKNOWN_ERROR]: t('api_error_add_ssh_key_unknown_error'),
  };

  return errors[err] || t('api_error_add_ssh_key_unknown_error');
}

export function getApiCreateInstanceError(err: ca2instances.CreateInstanceError): string {
  const errors = {
    [ca2instances.CreateInstanceError.CSE_INVALID_IMAGE_ID]: t('api_error_create_instance_invalid_image_id'),
    [ca2instances.CreateInstanceError.CSE_INVALID_INITIAL_SETUP_SCRIPT]: t(
      'api_error_create_instance_invalid_initial_setup_script',
    ),
    [ca2instances.CreateInstanceError.CSE_INVALID_PLAN_ID]: t('api_error_create_instance_invalid_plan_id'),
    [ca2instances.CreateInstanceError.CSE_INVALID_PROMO_CODE]: t('api_error_create_instance_invalid_promo_code'),
    [ca2instances.CreateInstanceError.CSE_INVALID_DATACENTER_ID]: t('api_error_create_instance_invalid_region_id'),
    [ca2instances.CreateInstanceError.CSE_ROOT_PASSWORD_OR_SSH_KEYS_REQUIRED]: t(
      'api_error_create_instance_password_or_key_required',
    ),
    [ca2instances.CreateInstanceError.CSE_INVALID_SSH_KEY_ID]: t('api_error_create_instance_invalid_ssh_key_id'),
    [ca2instances.CreateInstanceError.CSE_NOT_ENOUGH_BALANCE]: t('api_error_create_instance_not_enough_balance'),
    [ca2instances.CreateInstanceError.CSE_SERVER_NAME_CONTAINS_INVALID_CHARACTERS]: t(
      'api_error_create_instance_server_name_contains_invalid_chars',
    ),
    [ca2instances.CreateInstanceError.CSE_SERVER_NAME_TOO_LONG]: t('api_error_create_instance_server_name_too_long'),
    [ca2instances.CreateInstanceError.CSE_UNKNOWN]: t('api_error_create_instance_unknown_error'),
  };

  return errors[err] || t('api_error_create_instance_unknown_error');
}

export function getApiUpTopUpBalanceError(err: ca2billing.TopUpBalanceError): string {
  const errors = {
    [ca2billing.TopUpBalanceError.TUBE_AMOUNT_TOO_SMALL]: t('api_error_top_up_balance_amount_too_small'),
    [ca2billing.TopUpBalanceError.TUBE_INVALID_CURRENCY_CODE]: t('api_error_top_up_balance_invalid_currency_code'),
    [ca2billing.TopUpBalanceError.TUBE_INVALID_PAYMENT_SYSTEM]: t('api_error_top_up_balance_invalid_payment_system'),
    [ca2billing.TopUpBalanceError.TUBE_UNKNOWN_ERROR]: t('api_error_top_up_balance_unknown_error'),
  };

  return errors[err] || t('api_error_top_up_balance_unknown_error');
}

export function getApiInstanceOperationError(err: ca2instances.InstanceOperationError): string {
  const errors = {
    [ca2instances.InstanceOperationError.IOP_INSTANCE_NOT_FOUND]: t('api_error_instance_operation_not_found_error'),
    [ca2instances.InstanceOperationError.IOP_NOT_ALLOWED]: t('api_error_instance_operation_not_allowed_error'),
    [ca2instances.InstanceOperationError.IOP_UNKNOWN]: t('api_error_instance_operation_unknown_error'),
  };

  return errors[err] || t('api_error_instance_operation_unknown_error');
}

export function getApiInstanceUpdateError(err: ca2instances.UpdateInstanceError): string {
  const errors = {
    [ca2instances.UpdateInstanceError.UIE_INSTANCE_NOT_FOUND]: t('api_error_update_instance_not_found_error'),
    [ca2instances.UpdateInstanceError.UIE_SERVER_NAME_CONTAINS_INVALID_CHARACTERS]: t(
      'api_error_update_instance_name_contains_invalid_chars_error',
    ),
    [ca2instances.UpdateInstanceError.UIE_SERVER_NAME_TOO_LONG]: t('api_error_update_instance_name_too_long_error'),
    [ca2instances.UpdateInstanceError.UIE_UNKNOWN]: t('api_error_update_instance_unknown_error'),
  };

  return errors[err] || t('api_error_update_instance_unknown_error');
}

export function getApiCheckDomainError(err: ca2domains.CheckDomainError): string {
  const errors = {
    [ca2domains.CheckDomainError.CDN_INVALID_DOMAIN]: t('api_error_check_domain_invalid_domain'),
    [ca2domains.CheckDomainError.CDN_UNKNOWN]: t('api_error_check_domain_unknown_error'),
  };

  return errors[err] || t('api_error_check_domain_unknown_error');
}

export function getApiRegisterDomainError(err: ca2domains.RegisterDomainError): string {
  const errors = {
    [ca2domains.RegisterDomainError.RDE_INVALID_EXISTING_CONTACT_ID]: t(
      'api_error_register_domain_invalid_existing_contact',
    ),
    [ca2domains.RegisterDomainError.RDE_INVALID_NEW_CONTACT]: t('api_error_register_domain_invalid_new_contact'),
    [ca2domains.RegisterDomainError.RDE_INVALID_OR_NOT_AVAILABLE_DOMAIN]: t(
      'api_error_register_domain_invalid_or_not_available_domain',
    ),
    [ca2domains.RegisterDomainError.RDE_INVALID_TERM_YEARS]: t('api_error_register_domain_invalid_term_years'),
    [ca2domains.RegisterDomainError.RDE_NOT_ENOUGH_BALANCE]: t('api_error_register_domain_not_enough_balance'),
    [ca2domains.RegisterDomainError.RDE_UNKNOWN]: t('api_error_register_domain_unknown_error'),
  };

  return errors[err] || t('api_error_register_domain_unknown_error');
}

export function getApiDomainContactError(err: ca2domains.ContactError): string {
  const errors = {
    [ca2domains.ContactError.COCE_EMAIL_ADDRESS_REQUIRED]: t('api_error_domain_contact_email_required'),
    [ca2domains.ContactError.COCE_FIRST_NAME_REQUIRED]: t('api_error_domain_contact_first_name_required'),
    [ca2domains.ContactError.COCE_INVALID_CONTACT_ID]: t('api_error_domain_contact_invalid_contact_id'),
    [ca2domains.ContactError.COCE_LAST_NAME_REQUIRED]: t('api_error_domain_contact_last_name_required'),
    [ca2domains.ContactError.COCE_MAILING_ADDRESS_REQUIRED]: t('api_error_domain_contact_address_required'),
    [ca2domains.ContactError.COCE_MAILING_CITY_REQUIRED]: t('api_error_domain_contact_city_required'),
    [ca2domains.ContactError.COCE_MAILING_COUNTRY_CODE_REQUIRED]: t('api_error_domain_contact_country_code_required'),
    [ca2domains.ContactError.COCE_MAILING_STATE_REQUIRED]: t('api_error_domain_contact_state_required'),
    [ca2domains.ContactError.COCE_MAILING_ZIP_REQUIRED]: t('api_error_domain_contact_zip_code_required'),
    [ca2domains.ContactError.COCE_PHONE_NUMBER_REQUIRED]: t('api_error_domain_contact_phone_number_required'),
    [ca2domains.ContactError.COCE_EMAIL_ADDRESS_INVALID]: t('api_error_domain_contact_email_invalid'),
    [ca2domains.ContactError.COCE_PHONE_NUMBER_INVALID]: t('api_error_domain_contact_phone_number_invalid'),
    [ca2domains.ContactError.COCE_UNKNOWN]: t('api_error_domain_contact_unknown_error'),
  };

  return errors[err] || t('api_error_domain_contact_unknown_error');
}
