import {Box, styled} from '@mui/material';
import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import DropdownMenu from '../../../components/UI/DropdownMenu';
import MenuItem from '../../../components/UI/MenuItem';
import Typography from '../../../components/UI/Typography';
import {navigateToServerInfo} from '../../../routes/navigateTo';
import {useStore} from '../../../stores/AppStore';
import {Instance} from '../../../stores/Instance';
import {ModalType} from '../../../stores/ModalsStore';

const CustomButton = styled(Box)(({theme}) => ({
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  color: theme.palette.body.placeholder,
  '&:hover, &.active': {
    color: theme.palette.body.primary,
  },
  '&:after': {
    position: 'absolute',
    content: '"..."',
    height: '10px',
    overflow: 'hidden',
    lineHeight: 0,
  },
}));

const Menu = styled(DropdownMenu)(({theme}) => ({
  '& .MuiPaper-root': {
    borderRadius: '12px',
    minWidth: '150px',
    border: `1px solid ${theme.palette.border.primary}`,
  },
}));

interface IProps {
  instance: Instance;
}

export const ServerActionsDropdown: React.FC<IProps> = observer((props) => {
  const {modals} = useStore();
  const {t} = useTranslation();
  const [dropdownEl, setDropdownEl] = React.useState<HTMLElement | null>(null);

  const handleToggleDropdown = (event) => {
    setDropdownEl(event.currentTarget);
  };

  const handleCloseDropdown = () => {
    setDropdownEl(null);
  };

  const handleDelete = () => {
    handleCloseDropdown();

    modals.open(ModalType.CONFIRM, {
      maxWidth: 'sm',
      headerText: t('modal_confirm_delete_server_header_text', {serverName: props.instance.name}),
      title: (
        <Typography variant="body1" gutterBottom>
          {t('modal_confirm_delete_server_title')}
        </Typography>
      ),
      submitButtonText: t('modal_confirm_delete_server_submit_button'),
      content: <Typography variant="body1">{t('modal_confirm_delete_server_content')}</Typography>,
      callbacks: {
        submit: async () => {
          await props.instance.delete();
        },
        cancel: () => {
          modals.close(ModalType.CONFIRM);
        },
      },
    });
  };

  const handleOverview = () => {
    navigateToServerInfo(props.instance);
  };

  return (
    <>
      <CustomButton onClick={handleToggleDropdown} className={cn({active: !!dropdownEl})} />
      <Menu anchorEl={dropdownEl} open={Boolean(dropdownEl)} onClose={handleCloseDropdown}>
        <MenuItem onClick={handleOverview}>{t('servers_table_actions_overview')}</MenuItem>
        <MenuItem disabled>{t('servers_table_actions_usage')}</MenuItem>
        <MenuItem disabled>{t('servers_table_actions_accesses')}</MenuItem>
        <MenuItem
          sx={({palette}) => ({color: palette.error.primary})}
          onClick={handleDelete}
          disabled={!props.instance.allowDelete}
        >
          {t('server_action_destroy')}
        </MenuItem>
      </Menu>
    </>
  );
});

export default ServerActionsDropdown;
