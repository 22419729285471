import {Box, Button, InputLabel, SelectChangeEvent, ToggleButtonGroup, useTheme} from '@mui/material';
import {useFormik} from 'formik';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import {getApiCreateInstanceError} from '../../../api/getApiError';
import {ca2instances} from '../../../api/proto';
import RegionLabel from '../../../components/RegionLabel';
import CheckboxField from '../../../components/UI/CheckboxField';
import MenuItem from '../../../components/UI/MenuItem';
import Selector from '../../../components/UI/Selector';
import TextField from '../../../components/UI/TextField';
import ToggleButton from '../../../components/UI/ToggleButton';
import Typography from '../../../components/UI/Typography';
import {useStore} from '../../../stores/AppStore';
import {Plan} from '../../../stores/Plan';
import Addon from '../../../stores/Server/Addon';
import PlanCard from '../PlanCard';
import AddonSwitchBox from './AddonSwitchBox';
import AuthMethodField from './AuthMethodField';

interface IProps {
  plan: Plan;
  onSwitchToChoosePlan?(): void;
  onSubmit(setupPlanFormData: ca2instances.ICreateInstanceRequest): void;
}

export const SetupPlanForm: React.FC<IProps> = observer((props) => {
  const theme = useTheme();
  const {t} = useTranslation();
  const {instancesStore, notification, serversStore} = useStore();

  const {plan} = props;
  const initialImageFamily = plan.imageFamiliesList[0] || '';

  const [selectedImageFamily, setSelectedImageFamily] = React.useState<string>(initialImageFamily);
  const [selectedRegionId, setSelectedRegionId] = React.useState<string | null>(
    serversStore.regions.selectedId || plan.regions[0].id || null,
  );

  React.useEffect(() => {
    plan.loadPaymentOptions();
    plan.resetTotalRates();
  }, [plan]);

  const datacenters = React.useMemo(() => {
    return plan.findDatacentersByRegionId(selectedRegionId);
  }, [plan, selectedRegionId]);

  const formik = useFormik({
    initialValues: {
      name: '',
      datacenterId: datacenters?.[0]?.id || '',
      imageId: plan.getImagesList(initialImageFamily)[0]?.id || '',
      generateRootPassword: true,
      sshKeyIds: [],
      enabledAddonIds: [],
    },
    validationSchema: Yup.object({
      name: Yup.string(),
      datacenterId: Yup.string().required(t('create_server_setup_plan_datacenter_required')),
      imageId: Yup.string().required(t('create_server_setup_plan_image_required')),
      generateRootPassword: Yup.boolean(),
      sshKeyIds: Yup.array().of(Yup.number()),
      enabledAddonIds: Yup.array(),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values: Omit<ca2instances.ICreateInstanceRequest, 'planId'>) => {
      if (plan.id) {
        const {res, error} = await instancesStore.validateInstanceRequestData({planId: plan.id, ...values});

        if (error) {
          notification.error(error.message);
        }

        if (res) {
          const {errors} = res;

          if (errors?.length) {
            errors.forEach((error) => {
              const errorMessage = getApiCreateInstanceError(error);
              if (
                [
                  ca2instances.CreateInstanceError.CSE_SERVER_NAME_CONTAINS_INVALID_CHARACTERS,
                  ca2instances.CreateInstanceError.CSE_SERVER_NAME_TOO_LONG,
                ].includes(error)
              ) {
                formik.setFieldError('name', errorMessage);
              } else {
                notification.error(errorMessage);
              }
            });

            return;
          }

          props.onSubmit({planId: plan.id, ...values});
        }
      }
    },
  });

  const handleChangeImageFamily = (e: React.MouseEvent<HTMLElement>) => {
    const target = e.target as HTMLInputElement;
    setSelectedImageFamily(target.value);

    const imagesList = plan.getImagesList(target.value);
    formik.setFieldValue('imageId', imagesList[0]?.id || '');
  };

  const handleSetSshKeyIds = (ids: number[] | null) => {
    if (!ids?.length) {
      formik.setFieldValue('generateRootPassword', true);
    }

    formik.setFieldValue('sshKeyIds', ids || []);
  };

  const handleChangeGenerateRootPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('generateRootPassword', e.target.checked);
  };

  const handleChangeRegion = (e: SelectChangeEvent<unknown>) => {
    const target = e.target as HTMLInputElement;

    setSelectedRegionId(target.value);

    const datacenters_ = plan.findDatacentersByRegionId(target.value);
    formik.setFieldValue('datacenterId', datacenters_?.[0]?.id || '');
  };

  const toggleAddonSwitchHandler = (addon: Addon) => {
    const {id: addonId} = addon;

    if (!addonId) {
      return;
    }

    const addonIds = formik.values.enabledAddonIds ? [...formik.values.enabledAddonIds] : [];
    const index = addonIds?.indexOf(addonId);

    if (index === -1) {
      addonIds.push(addonId);
      plan.addAddonRate(addon);
    } else {
      addonIds.splice(index, 1);
      plan.subtractAddonRate(addon);
    }

    formik.setFieldValue('enabledAddonIds', addonIds);
  };

  const isAddonChecked = (addonId?: string | null) => {
    return !!formik.values.enabledAddonIds?.find((id) => id === addonId);
  };

  return (
    <div className="setup-plan-form">
      <PlanCard plan={props.plan} onChangePlan={props.onSwitchToChoosePlan} variant="slim" />

      <div className="setup-plan-form__body">
        <div className="setup-plan-form__form-wrap">
          <div className="setup-plan-form__form">
            <TextField
              fullWidth
              name="name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              placeholder={t('create_server_setup_plan_name_placeholder')}
              label={t('create_server_setup_plan_name_label')}
              error={!!formik.errors.name}
              helperText={formik.errors.name}
            />

            <Box
              className="setup-plan-form__row"
              sx={{display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: theme.spacing(4)}}
            >
              <Selector
                value={selectedRegionId}
                label={t('create_server_setup_plan_location_label')}
                onChange={handleChangeRegion}
              >
                {plan.regions.map((region) => (
                  <MenuItem key={region.id} value={region.id || ''}>
                    <RegionLabel regionOrDatacenter={region} />
                  </MenuItem>
                ))}
              </Selector>

              <Selector
                name="datacenterId"
                label={t('create_server_setup_plan_datacenter_label')}
                value={formik.values.datacenterId}
                onChange={formik.handleChange}
                error={!!formik.errors.datacenterId}
                helperText={formik.errors.datacenterId}
              >
                {datacenters.map((datacenter) => (
                  <MenuItem key={datacenter.id} value={datacenter.id || ''}>
                    <RegionLabel regionOrDatacenter={datacenter} />
                  </MenuItem>
                ))}
              </Selector>
            </Box>

            <div className="setup-plan-form__row">
              <InputLabel shrink>{t('create_server_setup_plan_image_family_label')}</InputLabel>
              <ToggleButtonGroup
                sx={{gridTemplateColumns: 'repeat(5, 1fr)'}}
                value={selectedImageFamily}
                exclusive
                onChange={handleChangeImageFamily}
              >
                {plan.imageFamiliesList.map((family) => (
                  <ToggleButton key={family} value={family}>
                    {family}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </div>

            <div className="setup-plan-form__row">
              <Selector
                name="imageId"
                label={t('create_server_setup_plan_image_version_label')}
                value={formik.values.imageId}
                onChange={formik.handleChange}
                error={!!formik.errors.imageId}
                helperText={formik.errors.imageId}
              >
                {plan.getImagesList(selectedImageFamily).map((image) => (
                  <MenuItem key={image.id} value={image.id || ''}>
                    {image.name}
                  </MenuItem>
                ))}
              </Selector>
            </div>

            {formik.values.sshKeyIds ? (
              <div className="setup-plan-form__row">
                <AuthMethodField onSetSshKeys={handleSetSshKeyIds} sshKeyIds={formik.values.sshKeyIds}>
                  <Box
                    sx={{
                      width: '100%',
                      paddingX: theme.spacing(4),
                      border: `1px solid ${theme.palette.border.primary}`,
                      borderRadius: '8px',
                    }}
                  >
                    <CheckboxField
                      label={t('create_server_setup_plan_auth_method_generate_root_password_label')}
                      onChange={handleChangeGenerateRootPassword}
                      checked={!!formik.values.generateRootPassword}
                      disabled={!formik.values.sshKeyIds.length}
                    />
                  </Box>
                </AuthMethodField>
              </div>
            ) : null}

            {plan.addons.map((addon) => (
              <AddonSwitchBox
                key={addon.id}
                addon={addon}
                checked={isAddonChecked(addon.id)}
                onChange={() => toggleAddonSwitchHandler(addon)}
              />
            ))}

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: theme.palette.backgroundSecondary,
                border: `1px solid ${theme.palette.border.primary}`,
                borderRadius: '8px',
                padding: theme.spacing(4),
              }}
            >
              <Box>
                <Typography variant="h3" component="span">
                  {plan.monthlyTotalRateFormatter.formatted}
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{marginLeft: theme.spacing(2), fontWeight: 'normal'}}
                  component="span"
                >
                  {t('create_server_setup_plan_auth_method_price_per_month_label')}
                </Typography>
              </Box>
              <Typography variant="subtitle1" sx={{fontWeight: 'normal'}}>
                ~{' '}
                {t('create_server_setup_plan_auth_method_price_per_hour_label', {
                  value: plan.hourlyTotalRateFormatter.formatted,
                })}
              </Typography>

              <Box sx={{marginTop: theme.spacing(3)}}>
                <Button variant="contained" size="large" onClick={() => formik.handleSubmit()}>
                  {t('create_server_setup_plan_to_payment_button')}
                </Button>
              </Box>
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
});

export default SetupPlanForm;
