import {generatePath} from 'react-router';
import {ServerInfoTab} from '../pages/ServerInfoPage/ServerInfoViewer';
import {Instance} from '../stores/Instance';
import Paths from './Paths';
import browserHistory from './browserHistory';

export const navigateToServerInfo = (instance: Instance) => {
  browserHistory.push(
    generatePath(Paths.ServerInfo, {
      instanceId: instance.id ? `${instance.id}` : '',
      tabType: ServerInfoTab.OVERVIEW,
    }),
  );
};
