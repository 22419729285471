import {Box, BoxProps, Button, styled, useTheme} from '@mui/material';
import cn from 'classnames';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as DeleteIcon} from '../../assets/icons/delete.svg';
import CheckboxField from '../UI/CheckboxField';

export function makeTldItem(tldName: string, isChecked?: boolean): TldItem {
  return {
    tldName,
    checked: isChecked || false,
  };
}

export type TldItem = {
  tldName: string;
  checked: boolean;
};

const ListBox = styled(Box)(({theme}) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(3),
}));

const TldBox = styled(Box)(({theme}) => ({
  border: `1px solid ${theme.palette.border.primary}`,
  borderRadius: '8px',
  paddingRight: theme.spacing(3),
  '&.checked': {
    backgroundColor: theme.palette.brand.primary,
    borderColor: theme.palette.brand.primary,
    color: theme.palette.body.tint,
  },
}));

interface IProps extends BoxProps {
  tldItems: TldItem[];
  onChangeItem?(tld: string): void;
  onClear?(): void;
}

const TldList: React.FC<IProps> = ({tldItems, onChangeItem, onClear, ...restProps}) => {
  const theme = useTheme();
  const {t} = useTranslation();
  const handleChange = (tld: string) => {
    onChangeItem?.(tld);
  };

  return (
    <ListBox {...restProps}>
      {tldItems.map(({tldName, checked}) => (
        <TldBox key={tldName} className={cn({checked})}>
          <CheckboxField
            label={`.${tldName}`}
            FormLabelProps={{
              labelPlacement: 'start',
            }}
            onChange={() => handleChange(tldName)}
            checked={checked}
          />
        </TldBox>
      ))}

      {onClear ? (
        <Button
          variant="outlined"
          size="large"
          onClick={onClear}
          startIcon={<DeleteIcon style={{fill: theme.palette.body.secondary}} />}
        >
          {t('action_button_clear')}
        </Button>
      ) : null}
    </ListBox>
  );
};

export default TldList;
