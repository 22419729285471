import {ButtonProps} from '@mui/material';
import {computed, makeAutoObservable, makeObservable, observable} from 'mobx';
import {ca2types} from '../../api/proto';
import {Breakpoint} from '../../modals/BaseModal';
import {ModalType} from './ModalType';

export interface ICallback {
  [name: string]: ((data?: IModalData) => void) | (<T>(data?: IModalData) => Promise<T>);
}

export interface IModalData {
  selectedSshKeysIds?: number[] | null;
  callbacks?: ICallback | null;
  syncCallbacks?: boolean;
  sshKey?: ca2types.ISSHKey | null;
  tldNames?: string[] | null;
  submitButtonColor?: ButtonProps['color'] | null;
  cancelButtonColor?: ButtonProps['color'] | null;
  cancelButtonText?: string | null;
  submitButtonText?: string | null;
  maxWidth?: Breakpoint | null;
  headerText?: string | null;
  title?: JSX.Element | null;
  content?: JSX.Element | null;
}

export interface IModalItem {
  type: ModalType;
  data: IModalData | null;
}

class ModalItem implements IModalItem {
  type: ModalType;
  @observable data: IModalData | null;

  constructor(props: IModalItem, private modals: ModalsStore) {
    makeObservable(this);

    this.type = props.type;
    this.data = props.data;

    Object.assign(this, props);
  }

  async trigger(name, data?: IModalData) {
    if (this.data?.callbacks && this.data.callbacks[name] instanceof Function) {
      if (this.data.syncCallbacks) {
        await this.data.callbacks[name](data || this.data);
      } else {
        this.data.callbacks[name](data || this.data);
      }
      this.modals.close(this.type);
    }
  }
}

export class ModalsStore {
  @observable private modals_ = new Map<ModalType, ModalItem>();

  @computed get list(): ModalItem[] {
    return Array.from(this.modals_.values());
  }

  constructor() {
    makeAutoObservable(this);
  }

  open(id: ModalType, data: IModalData | null = null) {
    this.modals_.set(id, new ModalItem({type: id, data: data}, this));
  }

  close(id) {
    this.modals_.delete(id);
  }

  getModalState(id): {isOpen: boolean; data?: IModalData | null; modalItem: ModalItem | null} {
    const modalItem = this.modals_.get(id);
    return modalItem ? {isOpen: true, data: modalItem.data, modalItem} : {isOpen: false, data: null, modalItem: null};
  }
}

export default ModalsStore;
