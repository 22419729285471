import LoadingButton from '@mui/lab/LoadingButton';
import {Button, useTheme} from '@mui/material';
import {useFormik} from 'formik';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import {getApiAddSshKeyError, getApiUpdateSshKeyError} from '../../api/getApiError';
import {ca2ssh, ca2types} from '../../api/proto';
import {ReactComponent as CloseIcon} from '../../assets/icons/close.svg';
import TextField from '../../components/UI/TextField';
import {useStore} from '../../stores/AppStore';
import {SshKeysViewerRow} from './SshKeysViewer';
interface IProps {
  sshKey?: ca2types.ISSHKey;
  onAddSshKey?(keyId: number): void;
  onClickCancelBtn?(): void;
  onClickRemoveBtn?(id: number): void;
}

const SshKeyForm: React.FC<IProps> = observer((props) => {
  const theme = useTheme();
  const {t} = useTranslation();
  const {sshKeysStore, notification} = useStore();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [isEditing, setIsEditing] = React.useState<boolean>(false);
  const [createdSshId, setCreatedSshId] = React.useState<number | null>(null);

  const isNewKey = !props.sshKey?.id && !createdSshId;

  const handleAddSshKey = async (values: ca2types.ISSHKey) => {
    setLoading(true);

    const {res, error} = await sshKeysStore.addSshKey(values);

    if (error) {
      notification.error(error.message);
    }

    if (res) {
      const {errors, sshKey} = res;

      if (errors?.length) {
        errors.forEach((key) => {
          const errorMessage = getApiAddSshKeyError(key);
          notification.error(errorMessage);

          if (key === ca2ssh.AddSSHKeyError.ASE_INVALID_PUBLIC_KEY_ERROR) {
            formik.setFieldError('publicKey', errorMessage);
          }
        });

        setLoading(false);
        return;
      }

      if (sshKey?.id) {
        props.onAddSshKey?.(sshKey.id);
        setCreatedSshId(sshKey?.id);
      }

      setCreatedSshId(sshKey?.id || null);
      setIsEditing(false);
    }

    setLoading(false);
  };

  const handleUpdateSshKey = async (values: ca2types.ISSHKey) => {
    setLoading(true);

    const {res, error} = await sshKeysStore.updateSshKey(values);

    if (error) {
      notification.error(error.message);
    }

    if (res) {
      const {errors, sshKey} = res;

      if (errors?.length) {
        errors.forEach((key) => {
          const errorMessage = getApiUpdateSshKeyError(key);
          notification.error(errorMessage);

          if (key === ca2ssh.UpdateSSHKeyError.USE_INVALID_PUBLIC_KEY_ERROR) {
            formik.setFieldError('publicKey', errorMessage);
          }
        });

        setLoading(false);
        return;
      }

      if (sshKey?.id) {
        props.onAddSshKey?.(sshKey.id);
        setCreatedSshId(sshKey?.id);
      }

      setCreatedSshId(sshKey?.id || null);
      setIsEditing(false);
    }

    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      id: props.sshKey?.id,
      name: props.sshKey?.name || '',
      publicKey: props.sshKey?.publicKey || '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t('component_ssh_keys_viewer_ssh_key_name_required')),
      publicKey: Yup.string().required(t('component_ssh_keys_viewer_ssh_key_required')),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      if (values.id) {
        handleUpdateSshKey(values);
      } else {
        handleAddSshKey(values);
      }
    },
    onReset: () => {
      setLoading(false);
      setCreatedSshId(null);
    },
  });

  const handleSubmit = () => {
    formik.handleSubmit();
  };

  const handleClickEdit = () => {
    setIsEditing(true);
  };

  const handleClickCancel = () => {
    setIsEditing(false);

    if (props.sshKey?.id) {
      formik.setValues({
        id: props.sshKey.id,
        name: props.sshKey?.name || '',
        publicKey: props.sshKey?.publicKey || '',
      });
    }

    props.onClickCancelBtn?.();
  };

  const handleClickRemove = () => {
    if (props.sshKey?.id) {
      props.onClickRemoveBtn?.(props.sshKey.id);
    }
  };

  return (
    <SshKeysViewerRow className="no-margin">
      <TextField
        name="name"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.name}
        error={!!formik.errors.name}
        helperText={formik.errors.name}
        placeholder={t('component_ssh_keys_viewer_ssh_key_name_placeholder')}
        sx={{
          marginRight: theme.spacing(2),
          '&.Mui-disabled': {
            color: theme.palette.body.primary,
          },
        }}
        disabled={!isNewKey && !isEditing}
      />

      <TextField
        fullWidth
        name="publicKey"
        placeholder={t('component_ssh_keys_viewer_ssh_key_placeholder')}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.publicKey}
        error={!!formik.errors.publicKey}
        helperText={formik.errors.publicKey}
        disabled={!isNewKey && !isEditing}
        sx={{
          '&.Mui-disabled': {
            color: theme.palette.body.primary,
          },
        }}
        rightAdornment={
          <>
            {isNewKey || isEditing ? (
              <>
                <Button
                  variant="outlined"
                  size="large"
                  sx={{marginRight: theme.spacing(2)}}
                  onClick={handleClickCancel}
                >
                  {t('action_button_cancel')}
                </Button>
                <LoadingButton variant="contained" size="large" loading={loading} onClick={handleSubmit}>
                  {t('action_button_save')}
                </LoadingButton>
              </>
            ) : (
              <>
                <LoadingButton
                  sx={{marginRight: theme.spacing(2)}}
                  variant="contained"
                  size="large"
                  loading={loading}
                  onClick={handleClickEdit}
                >
                  {t('action_button_edit')}
                </LoadingButton>
                <Button
                  size="large"
                  variant="outlined"
                  sx={{paddingRight: 0, paddingLeft: 0}}
                  onClick={handleClickRemove}
                >
                  <CloseIcon style={{fill: theme.palette.body.placeholder}} />
                </Button>
              </>
            )}
          </>
        }
      />
    </SshKeysViewerRow>
  );
});

export default SshKeyForm;
