import React, {useState} from 'react';
import cn from 'classnames';
import * as uuid from 'uuid';

const BACKSPACE_KEY = 8;
const LEFT_ARROW_KEY = 37;
const UP_ARROW_KEY = 38;
const RIGHT_ARROW_KEY = 39;
const DOWN_ARROW_KEY = 40;
const DELETE_KEY = 46;
const E_KEY = 69;

export type InputModeTypes = 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search' | undefined;

const defaultProps = {
  autoComplete: 'off',
  autoFocus: true,
  isValid: true,
  disabled: false,
  forceUppercase: false,
  fields: 5,
  value: '',
  type: 'text',
  filterKeyCodes: [189, 190],
  filterChars: ['-', '.'],
  filterCharsIsWhitelist: false,
  inputMode: 'numeric',
};

export interface PinInputProps {
  type?: 'text' | 'number' | 'password' | 'tel'

  fields?: number;
  placeholder?: string;
  value?: string;
  onChange?: (value: string) => void;
  name: string;
  touch?: (name: string) => void;
  untouch?: (name: string) => void
  className?: string;
  inputClassName?: string;
  isValid?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  autoFocus?: boolean;
  forceUppercase?: boolean;
  filterKeyCodes?: Array<number>;
  filterChars?: Array<string>;
  filterCharsIsWhitelist?: boolean;
  pattern?: string;
  inputMode?: InputModeTypes;
}

export const PinInput = (props: PinInputProps & typeof defaultProps) => {
  const {
    isValid,
    disabled,
    filterKeyCodes,
    forceUppercase,
    type,
    autoFocus,
    autoComplete,
    pattern,
    inputMode,
    placeholder,
    fields
  } = props;

  const [value, setValue] = useState<string>((forceUppercase ? props.value?.toUpperCase() : props.value) || '');
  const [id] = useState(uuid.v4());
  const [input, setInput] = useState<string[]>(new Array(fields).fill(0).map((a, i) => value[i] || ''));
  const [textInputs] = useState<(HTMLInputElement | null)[]>([]);


  function handleBlur(e) {
    handleTouch(e.target.value);
  }

  function handleTouch(val) {
    const {touch, untouch, name} = props;

    if (typeof touch === 'function' && typeof untouch === 'function') {
      if (val === '') {
        touch(name);
      } else {
        untouch(name);
      }
    }
  }

  function filterValue(val) {
    let _value = String(val);

    if (props.forceUppercase) {
      _value = _value.toUpperCase();
    }

    if (type === 'number') {
      _value = _value.replace(/[^\d]/g, '');
    }

    /** Filter Chars */
    _value = _value.split('').filter(currChar => {
      if (props.filterCharsIsWhitelist) {
        return props.filterChars.includes(currChar);
      }
      return !props.filterChars.includes(currChar);
    }).join('');

    return _value;
  }

  function handleChange(e) {
    const _value = filterValue(e.target.value);

    let fullValue = _value;

    if (_value !== '') {
      const _input = input.slice();

      if (_value.length > 1) {
        _value.split('').forEach((chart, i) => {
          if (Number(e.target.dataset.id) + i < props.fields) {
            _input[Number(e.target.dataset.id) + i] = chart;
          }
          return false;
        });
      } else {
        _input[Number(e.target.dataset.id)] = _value;
      }

      _input.forEach((s, i) => {
        const tInput = textInputs[i];
        if (tInput) {
          tInput.value = s;
        }
        return false;
      });

      const newTarget = textInputs[e.target.dataset.id < _input.length
        ? Number(e.target.dataset.id) + 1
        : e.target.dataset.id];

      if (newTarget) {
        newTarget.focus();
        newTarget.select();
      }

      fullValue = _input.join('');

      setValue(_input.join(''));
      setInput(_input);
    }

    if (props.onChange && fullValue) {
      props.onChange(fullValue);
    }

    handleTouch(fullValue);
  }

  function clearInput(target: number): string {
    const textInput = textInputs[target];
    if (textInput) {
      textInput.value = '';
    }
    const _input = input.slice();
    _input[target] = '';
    const _value = _input.join('');

    setValue(_value);
    setInput(_input);

    return _value;
  }

  function handleKeyDown(e) {
    const target = Number(e.target.dataset.id),
      nextTarget = textInputs[target + 1],
      prevTarget = textInputs[target - 1];

    if (filterKeyCodes.length > 0) {
      filterKeyCodes.forEach((item) => {
        if (item === e.keyCode) {
          e.preventDefault();
        }
      });
    }

    switch (e.keyCode) {
      case BACKSPACE_KEY:
        e.preventDefault();
        if (prevTarget) {
          prevTarget.focus();
          prevTarget.select();
        }
        if (props.onChange) {
          props.onChange(clearInput(target));
        }
        break;
      case DELETE_KEY:
        e.preventDefault();
        if (props.onChange) {
          props.onChange(clearInput(target));
        }
        break;
      case LEFT_ARROW_KEY:
        e.preventDefault();
        if (prevTarget) {
          prevTarget.focus();
          prevTarget.select();
        }
        break;
      case RIGHT_ARROW_KEY:
        e.preventDefault();
        if (nextTarget) {
          nextTarget.focus();
          nextTarget.select();
        }
        break;
      case UP_ARROW_KEY:
        e.preventDefault();
        break;
      case DOWN_ARROW_KEY:
        e.preventDefault();
        break;
      case E_KEY:
        if (e.target.type === 'number') {
          e.preventDefault();
          break;
        }
        break;
      default:
        break;
    }

    handleTouch(value);
  }

  return (
    <div
      className={cn('pin-input', props.className, `pin-input--${props.fields || defaultProps.fields}`)}
      style={{display: 'inline-block', ...props.style}}
    >
      {input.map((val, i) => {
        return (
          <input
            ref={(ref) => {
              textInputs[i] = ref;
            }}
            id={`${id}-${i}`}
            data-id={i}
            className={cn('pin-input__input', props.inputClassName)}
            style={props.inputStyle}
            autoFocus={autoFocus && i === 0}
            value={val}
            key={`input_${i}`}
            type={type}
            min={0}
            max={9}
            maxLength={input.length === i + 1 ? 1 : input.length}
            autoComplete={autoComplete}
            onFocus={(e) => e.target.select()}
            onBlur={handleBlur}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            disabled={disabled}
            data-valid={isValid}
            pattern={pattern}
            inputMode={inputMode}
            placeholder={placeholder}
          />
        );
      })}
    </div>
  );
};

PinInput.defaultProps = defaultProps;

export default PinInput;