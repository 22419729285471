import {Box, styled, Tab, Tabs, Typography} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useStore} from '../../stores/AppStore';
import {DomainsPageTab} from './DomainsPage';

const Container = styled(Box)(({theme}) => ({
  backgroundColor: theme.palette.backgroundPrimary,
  padding: theme.spacing(4),
  paddingBottom: 0,
}));

interface IProps {
  currentTab: DomainsPageTab;
  onChange?(tab: DomainsPageTab);
}

export const DomainsPageTabs: React.FC<IProps> = ({currentTab, onChange}) => {
  const {t} = useTranslation();
  const {domainsView} = useStore();

  if (!domainsView.showDomainsPageTabs) {
    return null;
  }

  const tabsData = Object.entries({
    [DomainsPageTab.SEARCH]: t('domains_search_tab'),
    [DomainsPageTab.TABLE]: t('domains_my_domains_tab'),
  });

  const handleChange = (_: React.SyntheticEvent, tab: DomainsPageTab) => {
    tab && onChange?.(tab);
  };

  return (
    <Container>
      <Typography variant="h4">{t('domains_title')}</Typography>

      <Tabs className="lineView" value={currentTab} onChange={handleChange}>
        {tabsData.map(([tab, title]) => (
          <Tab
            className="lineView"
            key={tab}
            label={<Typography variant="subtitle1">{title}</Typography>}
            value={tab}
          />
        ))}
      </Tabs>
    </Container>
  );
};

export default DomainsPageTabs;
