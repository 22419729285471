import { Box, styled, useTheme } from '@mui/material';
import cn from 'classnames';
import { getName } from 'country-list';
import React from 'react';
import { ca2types } from '../../../api/proto';
import CheckboxField from '../../../components/UI/CheckboxField';
import RadioField from '../../../components/UI/RadioField';
import Typography from '../../../components/UI/Typography';

const Container = styled(Box)(({theme}) => ({
  display: 'flex',
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.border.primary}`,
  borderRadius: '8px',

  '&:not(:last-child)': {
    marginBottom: theme.spacing(2),
  },

  '&.selectable:hover': {
    backgroundColor: theme.palette.backgroundSecondary,
    cursor: 'pointer',
  },
}));

interface IProps {
  contact: ca2types.IDomainContact;
  selectable?: boolean;
  checked?: boolean;
  onSelect?(id: number);
}

export const DomainContact: React.FC<IProps> = ({contact, selectable, checked, onSelect}) => {
  const theme = useTheme();
  const handleSelect = () => {
    if (selectable && contact.id) {
      onSelect?.(contact.id);
    }
  };

  const addressString = [
    getName(contact.mailingCountryCode),
    contact.mailingCity,
    contact.mailingAddress,
    contact.mailingState,
    contact.mailingZip,
  ]
    .filter((item) => !!item)
    .join(', ');

  return (
    <Container className={cn({selectable})} onClick={handleSelect}>
      <Box>
        {selectable ? (
          <RadioField checked={checked} sx={{padding: 0}} disableRipple />
        ) : (
          <CheckboxField sx={{padding: 0}} checked disabled />
        )}
      </Box>

      <Box sx={{flex: '1 1 auto', paddingLeft: theme.spacing(2)}}>
        <Typography
          variant="body1"
          sx={{marginTop: theme.spacing(0), marginBottom: theme.spacing(1)}}
        >{`${contact.firstName} ${contact.lastName}`}</Typography>

        <Typography variant="body2" color="body.tertiary" sx={{marginBottom: theme.spacing(0)}}>
          {addressString}
        </Typography>
        <Typography
          variant="body2"
          color="body.tertiary"
        >{`${contact.emailAddress}, ${contact.phoneNumber}`}</Typography>
      </Box>
    </Container>
  );
};

export default DomainContact;
