import {action, computed, makeObservable, observable} from 'mobx';
import {ca2auth} from '../../api/proto';
import Paths from '../../routes/Paths';
import browserHistory from '../../routes/browserHistory';
import {authPageOpen} from '../../routes/routes';
import browserStorage from '../../utils/browserStorage';
import APILayer from '../APILayer';
import {AppStore} from '../AppStore';

export const TOKEN_STORE_KEY = '_tkn';

const logoutBroadcastChannel = new BroadcastChannel('logout');

export class AuthStore extends APILayer {
  constructor(public app: AppStore) {
    super(app);
    makeObservable(this);

    logoutBroadcastChannel.addEventListener('message', () => {
      this.logoutRequest_();
    });
  }

  @observable private token_: string | null = null;

  getToken = (): string | null => {
    const stored = browserStorage.getItem(TOKEN_STORE_KEY);
    return this.token_ || stored;
  };

  @computed get isLoggedIn(): boolean {
    return !!this.getToken();
  }

  @action private setToken_ = (token: string | null) => {
    if (token) {
      browserStorage.setItem(TOKEN_STORE_KEY, token);
    }

    this.token_ = token;
  };

  @action reset = () => {
    browserStorage.removeItem(TOKEN_STORE_KEY);
    this.token_ = null;
  };

  emailVerification = async (start: ca2auth.IStartRequest) => {
    const {error, res} = await this.request({auth: {start}});
    return {error, res: res?.auth?.start};
  };

  enterCode = async (enterCode: ca2auth.IEnterCodeRequest) => {
    const {error, res} = await this.request({auth: {enterCode}});

    if (res?.auth?.enterCode) {
      this.processEnterCode_(res.auth.enterCode);
    }

    return {error, res: res?.auth?.enterCode};
  };

  @action private processEnterCode_ = (res: ca2auth.IEnterCodeResponse) => {
    if (res.successStep) {
      const {token, user} = res.successStep;

      if (token) {
        this.setToken_(token);
        this.app.wsApi.connect();
      }

      user && this.app.userStore.setProfile(user);

      if (token && user) {
        this.app.authenticatedInit();
      }
    }
  };

  enterPassword = async (enterPassword: ca2auth.IEnterPasswordRequest) => {
    const {error, res} = await this.request({auth: {enterPassword}});

    if (res?.auth?.enterPassword) {
      this.processEnterPassword_(res?.auth?.enterPassword);
    }

    return {error, res: res?.auth?.enterPassword};
  };

  @action private processEnterPassword_ = (res: ca2auth.IEnterPasswordResponse) => {
    if (res.successStep) {
      const {token, user} = res.successStep;

      if (token) {
        this.setToken_(token);
        this.app.wsApi.connect();
      }

      user && this.app.userStore.setProfile(user);

      if (token && user) {
        this.app.authenticatedInit();
      }
    }
  };

  logout = () => {
    logoutBroadcastChannel.postMessage('logout');

    if (this.isLoggedIn) {
      this.logoutRequest_();
    }
  };

  private logoutRequest_ = () => {
    this.request({auth: {logout: {}}});

    this.resetLoginSession_();
  };

  private resetLoginSession_ = () => {
    this.app.reset();
    this.app.wsApi.disconnect();

    if (!authPageOpen()) {
      browserHistory.push(Paths.Authorization);
    }
  };
}

export default AuthStore;
