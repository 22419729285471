import {Box, Dialog, DialogProps, DialogTitle, IconButton, styled, useTheme} from '@mui/material';
import {observer} from 'mobx-react';
import React from 'react';
import {ReactComponent as CloseIcon} from '../assets/icons/close.svg';
import SearchInput, {ISearchInputProps} from '../components/UI/SearchInput';
import {useStore} from '../stores/AppStore';
import {ModalType} from '../stores/ModalsStore';

const StyledDialog = styled(Dialog)(({theme}) => ({
  '& .MuiDialog-paper': {
    backgroundColor: theme.palette.backgroundTertiary,
    borderRadius: '8px',
  },
  '& .MuiDialogTitle-root': {
    padding: 0,
    paddingRight: '55px',
    color: theme.palette.body.primary,
    margin: 0,
    width: '100%',
  },
}));

const DialogHead = styled(Box)(({theme}) => ({
  position: 'relative',
  padding: `${theme.spacing(4)}`,
  borderBottom: `1px solid ${theme.palette.border.primary}`,
  backgroundColor: theme.palette.backgroundSecondary,

  '& .close-button': {
    position: 'absolute',
    right: 8,
    top: 8,
    color: theme.palette.body.placeholder,
  },
}));

export type Breakpoint = 'xs' | 'sm' | 'md' | 'lg';

interface BaseModalProps extends Omit<DialogProps, 'open'> {
  type: ModalType;
  title?: string;
  withSearch?: boolean;
  SearchInputProps?: ISearchInputProps;
}

const BaseModal: React.FC<BaseModalProps> = observer(
  ({type, title, withSearch, SearchInputProps, children, ...restProps}) => {
    const theme = useTheme();
    const {modals} = useStore();

    const modalState = modals.getModalState(type);

    const handleClose = () => {
      modals.close(type);
    };

    return (
      <StyledDialog
        open={modalState.isOpen}
        className="custom-scroll"
        onClose={handleClose}
        disableRestoreFocus
        {...restProps}
      >
        {title || withSearch ? (
          <DialogHead>
            <IconButton className="close-button" onClick={handleClose}>
              <CloseIcon
                style={{
                  fill: theme.palette.body.placeholder,
                }}
              />
            </IconButton>
            {title ? (
              <Box sx={{display: 'flex', alignItems: 'center'}}>
                <DialogTitle variant="subtitle1">{title}</DialogTitle>
              </Box>
            ) : null}

            {withSearch ? (
              <SearchInput
                {...SearchInputProps}
                sx={{marginTop: theme.spacing(4), backgroundColor: theme.palette.backgroundPrimary}}
                fullWidth
              />
            ) : null}
          </DialogHead>
        ) : null}
        {children}
      </StyledDialog>
    );
  },
);

export default BaseModal;
