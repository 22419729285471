import {action, makeObservable, observable} from 'mobx';
import {ca2ssh, ca2types} from '../api/proto';
import APILayer from './APILayer';
import {AppStore} from './AppStore';

export class SshKeysStore extends APILayer {
  constructor(public app: AppStore) {
    super(app);
    makeObservable(this);
  }

  @observable isInit: boolean = false;

  @observable list: ca2types.ISSHKey[] = [];

  @action reset = () => {
    this.list = [];
  };

  @action init = async () => {
    if (this.isInit) {
      return;
    }

    await this.loadList_();

    this.isInit = true;
  };

  private loadList_ = async () => {
    const {res} = await this.request({
      ssh: {
        listSshKeys: {},
      },
    });

    if (res?.ssh?.listSshKeys) {
      this.processLoadList_(res.ssh.listSshKeys);
    }
  };

  @action processLoadList_ = ({sshKeys}: ca2ssh.IListSSHKeysResponse) => {
    if (sshKeys) {
      this.list = sshKeys;
    }
  };

  addSshKey = async (addSshKey: ca2ssh.IAddSSHKeyRequest) => {
    const {res, error} = await this.request({
      ssh: {
        addSshKey,
      },
    });

    if (res?.ssh?.addSshKey) {
      this.processCreateSshKey_(res.ssh.addSshKey);
    }

    return {res: res?.ssh?.addSshKey, error};
  };

  @action private processCreateSshKey_ = (res: ca2ssh.IAddSSHKeyResponse) => {
    if (res.sshKey) {
      this.list = [...this.list, res.sshKey];
    }
  };

  updateSshKey = async (updateSshKey: ca2ssh.IUpdateSSHKeyRequest) => {
    const {res, error} = await this.request({
      ssh: {
        updateSshKey,
      },
    });

    if (res?.ssh?.updateSshKey) {
      this.processUpdateSshKey_(res.ssh.updateSshKey);
    }

    return {res: res?.ssh?.updateSshKey, error};
  };

  @action private processUpdateSshKey_ = ({sshKey}: ca2ssh.IUpdateSSHKeyResponse) => {
    if (sshKey) {
      this.list = this.list.map((item) => (item.id === sshKey?.id ? sshKey : item));
    }
  };

  deleteSshKey = async (deleteSshKey: ca2ssh.IDeleteSSHKeyRequest) => {
    const {res, error} = await this.request({
      ssh: {
        deleteSshKey,
      },
    });

    if (res?.ssh?.deleteSshKey && deleteSshKey.id) {
      this.processDeleteSshKey_(res.ssh.deleteSshKey, deleteSshKey.id);
    }

    return {res: res?.ssh?.deleteSshKey, error};
  };

  @action private processDeleteSshKey_ = (res: ca2ssh.IDeleteSSHKeyResponse, sshKeyId: number) => {
    if (!res.errors?.length) {
      this.list = this.list.filter(({id}) => sshKeyId !== id);
    }
  };

  findByIds = (ids: number[]): ca2types.ISSHKey[] => {
    return this.list.filter(({id}) => id && ids.includes(id));
  };
}
