import {LoadingButton} from '@mui/lab';
import {Box, Button, styled, useTheme} from '@mui/material';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ca2domains, ca2types} from '../../../api/proto';
import {ReactComponent as PlusIcon} from '../../../assets/icons/plus.svg';
import Typography from '../../../components/UI/Typography';
import {useStore} from '../../../stores/AppStore';
import DomainContact from './DomainContact';
import DomainContactForm, {IDomainContactFormRef} from './DomainContactForm';

export const ContactsGridBox = styled(Box)(({theme}) => ({
  display: 'grid',
  maxWidth: 675,
  columnGap: theme.spacing(4),
  gridTemplateColumns: '1fr',
  marginBottom: theme.spacing(4),
}));

export const BorderBox = styled(Box)(({theme}) => ({
  padding: theme.spacing(4),
  '&:not(:last-child)': {
    borderBottom: `1px solid ${theme.palette.border.primary}`,
  },
}));

export interface IDomainContactsRef extends Omit<IDomainContactFormRef, 'submitForm'> {
  getSelectedContactId(): number | null;
}

interface IProps {
  existingContactId?: number | null;
}

export const DomainContacts = observer(
  React.forwardRef<IDomainContactsRef, IProps>((props, ref) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const {
      domainsStore: {contacts},
    } = useStore();

    const domainContactFormRef = React.useRef<IDomainContactFormRef>(null);

    const [loading, setLoading] = React.useState<boolean>(false);

    const [showNewContactForm, setShowNewContactForm] = React.useState<boolean>(!contacts.list.length);
    const [selectedContactId, setSelectedContactId] = React.useState<number | null>(
      props.existingContactId || contacts.list[0].id || null,
    );

    React.useImperativeHandle(ref, () => ({
      getFormFields: async () => {
        const fields = await domainContactFormRef.current?.getFormFields();
        return fields || null;
      },
      setApiErrors: (errors?: ca2domains.ContactError[] | null) => {
        domainContactFormRef.current?.setApiErrors(errors);
      },
      setFormValues: (values: ca2types.IDomainContact) => {
        domainContactFormRef.current?.setFormValues(values);
      },
      getSelectedContactId: () => {
        return selectedContactId;
      },
    }));

    const handleOpenForm = () => {
      setShowNewContactForm(true);
    };

    const handleCloseForm = () => {
      setShowNewContactForm(false);
    };

    const handleSelectContact = (contactId: number) => {
      setSelectedContactId(contactId);
    };

    const handleSaveAndUseNewAddress = async () => {
      setLoading(true);
      const newContactId = await domainContactFormRef.current?.submitForm();

      if (newContactId) {
        setSelectedContactId(newContactId);
        setShowNewContactForm(false);
      }

      setLoading(false);
    };

    return (
      <BorderBox>
        <Typography variant="label3" sx={{marginBottom: theme.spacing(2)}}>
          {t('domain_contacts_title')}
        </Typography>

        <ContactsGridBox>
          {contacts.list.map((contact) => (
            <DomainContact
              key={contact.id}
              contact={contact}
              checked={selectedContactId === contact.id}
              selectable={contacts.list.length > 1}
              onSelect={handleSelectContact}
            />
          ))}
        </ContactsGridBox>

        {showNewContactForm ? <DomainContactForm ref={domainContactFormRef} /> : null}

        {!showNewContactForm ? (
          <Button
            variant="outlined"
            size="large"
            onClick={handleOpenForm}
            startIcon={<PlusIcon style={{fill: theme.palette.body.primary}} />}
          >
            {t('domain_contacts_add_new_contact_button')}
          </Button>
        ) : contacts.list.length ? (
          <Box>
            <LoadingButton
              variant="contained"
              size="large"
              loading={loading}
              sx={{marginRight: theme.spacing(2)}}
              onClick={handleSaveAndUseNewAddress}
            >
              {t('domain_contacts_save_and_use_new_address_button')}
            </LoadingButton>
            <Button variant="outlined" size="large" onClick={handleCloseForm}>
              {t('action_button_cancel')}
            </Button>
          </Box>
        ) : null}
      </BorderBox>
    );
  }),
);

export default DomainContacts;
