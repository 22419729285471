import {LoadingButton} from '@mui/lab';
import {Box, InputLabel, ListItemIcon, SelectChangeEvent, ToggleButton, styled, useTheme} from '@mui/material';
import {useFormik} from 'formik';
import Long from 'long';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import {ca2types} from '../../../api/proto';
import {ReactComponent as CoinsPaidIcon} from '../../../assets/icons/coinspaid.svg';
import {CURRENCY_SIGN} from '../../../constants';
import {useStore} from '../../../stores/AppStore';
import DomainSuggestion from '../../../stores/Domain/DomainSuggestion';
import {Plan} from '../../../stores/Plan';
import convertStringToNumber from '../../../utils/convert/convertStringToNumber';
import PriceFormatter from '../../../utils/priceFormatter';
import CurrencyIcon from '../../CurrencyIcon';
import InlineToggleButtonGroup from '../../UI/InlineToggleButtonGroup';
import MenuItem from '../../UI/MenuItem';
import Selector from '../../UI/Selector';
import TextField from '../../UI/TextField';
import Typography from '../../UI/Typography';
import BalanceTitle from '../BalanceTitle';
import BalanceWithAmountHoursLeft from '../BalanceWithAmountHoursLeft';
import {OrderInstance, TopUpBalanceRequestData} from '../PaymentViewer';
import TopUpBalanceWarning from './TopUpBalanceWarning';

const ProviderBox = styled(Box)(({theme}) => ({
  border: `1px solid ${theme.palette.border.primary}`,
  borderRadius: '12px',
  padding: theme.spacing(4),
}));

interface IProps {
  orderInstance?: OrderInstance;
  onSubmit(data: TopUpBalanceRequestData): Promise<void>;
  onClickPayButton?(): Promise<void>;
}

export const TopUpBalanceForm: React.FC<IProps> = observer((props) => {
  const {t} = useTranslation();
  const theme = useTheme();
  const {billingStore, instancesStore} = useStore();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [loadingCreatingServer, setLoadingCreatingServer] = React.useState<boolean>(false);

  const [selectedCurrencyCode, setSelectedCurrencyCode] = React.useState<string>('');

  React.useEffect(() => {
    const hasBTC = billingStore.currencies.some(({code}) => code === 'BTC');
    setSelectedCurrencyCode(hasBTC ? 'BTC' : billingStore.currencies[0]?.code || '');
  }, [billingStore.currencies]);

  const selectedCurrency = React.useMemo(() => {
    return billingStore.currencies.find((cur) => selectedCurrencyCode === cur.code);
  }, [selectedCurrencyCode, billingStore.currencies]);

  const maxAmountFormatter = React.useMemo(() => {
    const defaultMaxAmountFormatter = new PriceFormatter({
      points: Long.fromNumber(10000000000), // 1,000,000 dollars * 10,000 points per dollar,
      formatted: '$1,000,000',
    });

    if (props.orderInstance instanceof Plan) {
      return props.orderInstance.maxAmountFormatter || defaultMaxAmountFormatter;
    }

    return defaultMaxAmountFormatter;
  }, [props.orderInstance]);

  const initialAmount = React.useMemo(() => {
    if (props.orderInstance instanceof Plan) {
      return props.orderInstance.monthlyTotalRateFormatter.dollars;
    } else if (props.orderInstance instanceof DomainSuggestion) {
      return props.orderInstance.registrationPriceFormatter.dollars;
    }

    return 0;
  }, [props.orderInstance]);

  const formik = useFormik({
    initialValues: {
      amount: initialAmount,
    },
    validationSchema: Yup.object({
      amount: Yup.string()
        .required(t('component_payment_viewer_amount_required'))
        .test(
          'is-number',
          t('component_payment_viewer_amount_not_number_validation'),
          (value) => !isNaN(parseFloat(value)),
        )
        .test(
          'min-number',
          t('component_payment_viewer_amount_min_value', {
            amount: selectedCurrency?.minAmountFormatter.dollars,
          }),
          (value) => {
            return selectedCurrency?.minAmountFormatter.isLessThanDollars(parseFloat(value));
          },
        )
        .test(
          'max-number',
          t('component_payment_viewer_amount_max_value', {
            amount: maxAmountFormatter.formatted,
          }),
          (value) => {
            return maxAmountFormatter.isGreaterOrEqualDollars(parseFloat(value));
          },
        ),
    }),
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values) => {
      if (!selectedCurrency?.code) {
        return;
      }

      setLoading(true);

      const numericAmountUsd = convertStringToNumber(values.amount);
      const amountInCents = Math.round(numericAmountUsd * 100);

      await props.onSubmit({
        amount: amountInCents,
        currencyCode: selectedCurrency?.code,
      });

      setLoading(false);
    },
    onReset: () => {
      setLoading(false);
    },
  });

  const amountFormatter = React.useMemo(() => {
    const numericAmountValue = convertStringToNumber(formik.values.amount);
    const points = Long.fromNumber(numericAmountValue * 10000); // Convert dollars to points (1 dollar = 10,000 points)

    // Format the dollar value as a string
    const formatted = `$${numericAmountValue.toFixed(2)}`;

    // Create a PriceFormatter instance with the converted points and formatted string
    const defaultAmountFormatter = new PriceFormatter({
      points: points,
      formatted: formatted,
    });

    return defaultAmountFormatter;
  }, [formik.values.amount]);

  const handleChangeMonthlyAmount = (e: React.MouseEvent<HTMLElement>) => {
    const target = e.target as HTMLInputElement;
    const value = +target.value;

    if (!isNaN(value)) {
      formik.setFieldValue('amount', value);
    }
  };

  const handleChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/[^\d.]/g, '');

    if (value.length > 0 && !/^\d/.test(value[0])) {
      value = value.substring(1);
    }

    const amountStr = `${maxAmountFormatter.dollars}`;

    if (value.length > amountStr.length) {
      value = value.substring(0, amountStr.length);
    }

    formik.setFieldValue('amount', value);
  };

  const handleChangeCurrencyType = (e: SelectChangeEvent<unknown>) => {
    const value = e.target.value !== 'null' ? (e.target.value as string) : null;
    value && setSelectedCurrencyCode(value);
  };

  const handleClickPayButton = async () => {
    setLoadingCreatingServer(true);
    await props.onClickPayButton?.();
    setLoadingCreatingServer(false);
  };

  const handleSubmitForm = () => {
    formik.submitForm();
  };

  return (
    <>
      <BalanceTitle
        balanceFormatted={billingStore.balanceFormatter.formatted}
        sx={(theme) => ({marginBottom: theme.spacing(4)})}
      />

      {props.orderInstance ? (
        <TopUpBalanceWarning hasBalance={!!billingStore.balanceFormatter.points} orderInstance={props.orderInstance} />
      ) : null}

      {props.orderInstance instanceof Plan && props.orderInstance?.paymentOptions.length ? (
        <InlineToggleButtonGroup
          sx={(theme) => ({
            marginBottom: theme.spacing(4),
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
            gap: 0,
            height: 36,
          })}
          value={amountFormatter.dollars}
          onChange={handleChangeMonthlyAmount}
          exclusive
        >
          {props.orderInstance.paymentOptions.map(({amountFormatter, title}) => (
            <ToggleButton key={amountFormatter.dollars} value={amountFormatter.dollars}>
              {title}
            </ToggleButton>
          ))}
        </InlineToggleButtonGroup>
      ) : null}

      <TextField
        fullWidth
        name="amount"
        startAdornment={CURRENCY_SIGN}
        onChange={handleChangeAmount}
        onBlur={formik.handleBlur}
        value={formik.values.amount}
        label={t('component_payment_viewer_amount_label')}
        error={!!formik.errors.amount}
        helperText={
          formik.errors.amount ||
          t('component_payment_viewer_min_amount_label', {
            amount: selectedCurrency?.minAmountFormatter.formatted,
          })
        }
      />

      {props.orderInstance instanceof Plan && instancesStore.hasInstances ? (
        <BalanceWithAmountHoursLeft amountFormatter={amountFormatter} plan={props.orderInstance} />
      ) : null}

      <ProviderBox>
        <InputLabel shrink>{t('component_payment_viewer_provider_label')}</InputLabel>
        <InlineToggleButtonGroup value={billingStore.paymentSystem} exclusive>
          <ToggleButton value={ca2types.PaymentSystem.PS_COINS_PAID}>
            <CoinsPaidIcon style={{fill: theme.palette.body.primary, marginRight: theme.spacing(2)}} />
            CoinsPaid
          </ToggleButton>
          <ToggleButton value="" disabled>
            Bitpay
          </ToggleButton>
        </InlineToggleButtonGroup>

        <Selector
          formControlProps={{sx: (theme) => ({margin: `${theme.spacing(4)} 0`})}}
          name="currencyCode"
          label={t('component_payment_viewer_currency_label')}
          value={selectedCurrencyCode}
          onChange={handleChangeCurrencyType}
        >
          {billingStore.currencies.map(({code}) => (
            <MenuItem key={code} value={code || ''}>
              <Box sx={{display: 'flex', alignItems: 'center'}}>
                {code ? (
                  <ListItemIcon sx={(theme) => ({marginRight: theme.spacing(2)})}>
                    <CurrencyIcon code={code} />
                  </ListItemIcon>
                ) : null}
                <Typography variant="body1" component="span">
                  {code}
                </Typography>
              </Box>
            </MenuItem>
          ))}
        </Selector>

        <Box sx={(theme) => ({textAlign: 'center', marginBottom: theme.spacing(4)})}>
          <Typography variant="h4" sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            {`
              ${amountFormatter.convertToCrypto(selectedCurrency?.rateFormatter.points || 0)}
              ${selectedCurrency?.code || ''}
            `}
            {selectedCurrency?.code ? (
              <CurrencyIcon code={selectedCurrency.code} style={{marginLeft: theme.spacing(2)}} />
            ) : null}
          </Typography>
          <Typography variant="body1">~ {amountFormatter.formatted}</Typography>
        </Box>

        <LoadingButton
          variant="contained"
          fullWidth
          size="large"
          loading={loading}
          disabled={!amountFormatter.points || Object.keys(formik.errors).length > 0}
          onClick={handleSubmitForm}
        >
          {t('component_payment_viewer_top_up_balance_button')}
        </LoadingButton>
      </ProviderBox>

      {props.orderInstance instanceof Plan && props.orderInstance.hasBalanceToPayFirstHours ? (
        <Box sx={(theme) => ({marginTop: theme.spacing(4), textAlign: 'center'})}>
          <LoadingButton
            variant="outlined"
            loading={loadingCreatingServer}
            fullWidth
            size="large"
            onClick={handleClickPayButton}
          >
            {t('component_payment_viewer_pay_and_launch_server_without_top_up_button', {
              amount: props.orderInstance.firstHoursRateFormatter.formatted,
            })}
          </LoadingButton>
          <Typography
            variant="body3"
            sx={(theme) => ({marginTop: theme.spacing(2), color: theme.palette.body.tertiary})}
          >
            {t('component_payment_viewer_minimum_payment_duration_for_server', {
              hours: props.orderInstance.firstPaymentHours,
            })}
          </Typography>
        </Box>
      ) : props.orderInstance instanceof DomainSuggestion && props.orderInstance.hasBalanceToOrderDomain ? (
        <Box sx={(theme) => ({marginTop: theme.spacing(4), textAlign: 'center'})}>
          <LoadingButton
            variant="outlined"
            loading={loadingCreatingServer}
            fullWidth
            size="large"
            onClick={handleClickPayButton}
          >
            {t('component_payment_viewer_pay_and_order_domain_without_top_up_button', {
              amount: props.orderInstance.registrationPriceFormatter.formatted,
            })}
          </LoadingButton>
        </Box>
      ) : null}
    </>
  );
});

export default TopUpBalanceForm;
