import {observer} from 'mobx-react';
import React from 'react';
import {animated} from 'react-spring';

import {useLocation, useNavigate} from 'react-router';
import {useOrderTransition} from '../../hooks/useOrderTransition';
import Paths from '../../routes/Paths';
import getQueryStringParam from '../../utils/getQueryStringParam';
import EmailVerificationForm from './EmailVerificationForm';
import EnterPasswordForm from './EnterPasswordForm';
import PersonalInfoForm from './PersonalInfoForm';
import VerificationCodeForm from './VerificationCodeForm';

export enum AuthStepType {
  EMAIL_VERIFICATION,
  VERIFICATION_CODE,
  PERSONAL_INFO,
  ENTER_PASSWORD,
}

type LocationState = {
  step?: AuthStepType;
};

const debug: string = getQueryStringParam('debug');
const qsStep: string = getQueryStringParam('step');

const INITIAL_STEP = debug && qsStep ? parseFloat(qsStep) : AuthStepType.EMAIL_VERIFICATION;

const AuthPage: React.FC = observer(() => {
  const location = useLocation();
  const state = location.state as LocationState;
  const navigate = useNavigate();

  const [prevStep, setPrevStep] = React.useState<number>(AuthStepType.PERSONAL_INFO);
  const [currentStep, setCurrentStep] = React.useState<number>(state?.step || INITIAL_STEP);

  const switchToStep = (step: AuthStepType) => {
    setPrevStep(currentStep);
    setCurrentStep(step);
  };

  const handleSubmitEmailVerification = (switchToEnterPasswordStep: boolean = false) => {
    switchToStep(switchToEnterPasswordStep ? AuthStepType.ENTER_PASSWORD : AuthStepType.VERIFICATION_CODE);
  };

  const handleSubmitVerificationCode = () => {
    switchToStep(AuthStepType.PERSONAL_INFO);
  };

  const handleSubmitPersonalInfo = () => {
    navigate(Paths.Servers);
  };

  const handleSubmitEnterPassword = () => {
    navigate(Paths.Servers);
  };

  const handleEditEmail = () => {
    switchToStep(AuthStepType.EMAIL_VERIFICATION);
  };

  const transitions = useOrderTransition({
    prevStep,
    currentStep,
  });

  const bodyHeight: Record<AuthStepType, number> = {
    [AuthStepType.EMAIL_VERIFICATION]: 250,
    [AuthStepType.VERIFICATION_CODE]: 516,
    [AuthStepType.PERSONAL_INFO]: 452,
    [AuthStepType.ENTER_PASSWORD]: 336,
  };

  return (
    <div className="auth-page">
      <div className="auth-page__body" style={{minHeight: bodyHeight[currentStep] || '100%'}}>
        {transitions((styles, step) =>
          step === AuthStepType.EMAIL_VERIFICATION ? (
            <animated.div style={styles} className="animated-tab">
              <EmailVerificationForm onSubmit={handleSubmitEmailVerification} />
            </animated.div>
          ) : step === AuthStepType.VERIFICATION_CODE ? (
            <animated.div style={styles} className="animated-tab">
              <VerificationCodeForm
                onSubmit={handleSubmitVerificationCode}
                onEditEmail={handleEditEmail}
                isRegistrationFlow
              />
            </animated.div>
          ) : step === AuthStepType.PERSONAL_INFO ? (
            <animated.div style={styles} className="animated-tab">
              <PersonalInfoForm onSubmit={handleSubmitPersonalInfo} />
            </animated.div>
          ) : step === AuthStepType.ENTER_PASSWORD ? (
            <animated.div style={styles} className="animated-tab">
              <EnterPasswordForm onSubmit={handleSubmitEnterPassword} />
            </animated.div>
          ) : null,
        )}
      </div>
    </div>
  );
});

export default AuthPage;
