import {observer} from 'mobx-react-lite';
import React from 'react';
import {useStore} from '../stores/AppStore';
import Loader from './UI/Loader';

const AppLoader: React.FC<React.PropsWithChildren> = observer(({children}) => {
  const {isAppInit} = useStore();

  if (isAppInit) {
    return <>{children}</>;
  }

  return (
    <div className="app-loader">
      <Loader className="app-loader__loader" size={25} loading />
    </div>
  );
});

export default AppLoader;
