import {Box, Button, styled, useTheme} from '@mui/material';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as FilterIcon} from '../../../../assets/icons/filter-by-type.svg';
import TldList, {makeTldItem, TldItem} from '../../../../components/TldGroup.tsx/TldList';
import CheckboxField from '../../../../components/UI/CheckboxField';
import {useStore} from '../../../../stores/AppStore';
import {ModalType} from '../../../../stores/ModalsStore';
import {IModalData} from '../../../../stores/ModalsStore/ModalsStore';
import TopTldsBar from './TopTldsBar';

const Container = styled(Box)(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',

  padding: theme.spacing(2),
  border: `2px solid ${theme.palette.border.primary}`,
  borderTop: 'none',
  borderBottomRightRadius: '12px',
  borderBottomLeftRadius: '12px',
}));

export const DomainFilterBar = observer(() => {
  const {domainsStore, domainsView, modals} = useStore();
  const {t} = useTranslation();
  const theme = useTheme();

  const handleSetSelectedTldNames = (tldNames: string[]) => {
    domainsStore.search.setSelectedTldNames(tldNames);
  };

  const handleSelectTldNames = (data: IModalData | undefined) => {
    if (data?.tldNames) {
      handleSetSelectedTldNames(data.tldNames);
    }
  };

  const handleOpenFilterModal = () => {
    modals.open(ModalType.TLD_FILTER, {
      tldNames: domainsStore.search.selectedTldNames,
      callbacks: {
        onSelectTldNames: handleSelectTldNames,
      },
    });
  };

  const selectedTldItems = React.useMemo<TldItem[]>(() => {
    return domainsStore.search.selectedTldNames?.map((tld) => makeTldItem(tld, true)) || [];
  }, [domainsStore.search.selectedTldNames]);

  return (
    <>
      <Container>
        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
          <Box sx={{marginLeft: theme.spacing(1)}}>
            <CheckboxField
              label={t('domains_search_filter_fide_taken_label')}
              onChange={domainsStore.search.toggleHideTakenDomains}
              checked={domainsStore.search.hideTakenDomains}
            />
            <CheckboxField
              label={t('domains_search_filter_hide_premium_label')}
              onChange={domainsStore.search.toggleHidePremiumDomains}
              checked={domainsStore.search.hidePremiumDomains}
              disabled={domainsView.isSearchDomainStart && !domainsStore.search.hasPremiumDomains}
            />
          </Box>

          <Button
            variant="outlined"
            onClick={handleOpenFilterModal}
            startIcon={<FilterIcon style={{fill: theme.palette.body.primary}} />}
          >
            {t('domains_search_filter_button')}
          </Button>
        </Box>

        <Box sx={{maxHeight: '160px', overflow: 'auto'}} className="custom-scroll">
          {selectedTldItems.length ? (
            <TldList
              tldItems={selectedTldItems}
              sx={{marginTop: theme.spacing(2)}}
              onClear={() => handleSetSelectedTldNames([])}
            />
          ) : null}
        </Box>
      </Container>

      {!domainsView.isSearchDomainStart ? (
        <TopTldsBar topTlds={domainsStore.search.topTlds} onTldClick={domainsStore.search.addToSelectedTldName} />
      ) : null}
    </>
  );
});

export default DomainFilterBar;
