import {Checkbox, CheckboxProps, FormControlLabel, FormControlLabelProps, useTheme} from '@mui/material';
import React from 'react';
import {ReactComponent as CheckboxCheckedIcon} from '../../assets/icons/checkbox-checked.svg';
import {ReactComponent as CheckboxIcon} from '../../assets/icons/checkbox.svg';

interface ICheckboxFieldProps extends CheckboxProps {
  label?: string | JSX.Element;
  FormLabelProps?: Omit<FormControlLabelProps, 'control' | 'label'>;
}

export const CheckboxField: React.FC<ICheckboxFieldProps> = ({label, FormLabelProps, ...restProps}) => {
  const theme = useTheme();

  const checkboxEl = (
    <Checkbox
      icon={<CheckboxIcon style={{stroke: theme.palette.body.placeholder}} />}
      checkedIcon={<CheckboxCheckedIcon style={{fill: theme.palette.brand.primary}} />}
      {...restProps}
    />
  );

  if (!label) {
    return checkboxEl;
  }

  return <FormControlLabel {...FormLabelProps} control={checkboxEl} label={label} />;
};

export default CheckboxField;
