import {Box, styled} from '@mui/material';
import {observer} from 'mobx-react';
import React from 'react';
import {useStore} from '../../stores/AppStore';
import {ModalType} from '../../stores/ModalsStore';
import {IModalData} from '../../stores/ModalsStore/ModalsStore';
import AddSshKeyDropdown from './AddSshKeyDropdown';
import ServerSshKeyItem from './ServerSshKeyItem';
import SshKeyForm from './SshKeyForm';

export const SshKeysViewerRow = styled(Box)(({theme}) => ({
  display: 'flex',
  width: '100%',
  '&:not(.no-margin)': {
    marginBottom: theme.spacing(2),
  },
}));

interface IProps {
  sshKeyIds: number[];
  onAddSshKey?(id: number): void;
  onDeleteSshKey?(id: number): void;
  listItemVariation?: 'plan' | 'server';
  dropdownPosition?: 'top' | 'bottom';
  hideAddNewKeyButton?: boolean;
}

export const SshKeysViewer: React.FC<IProps> = observer((props) => {
  const {modals, sshKeysStore} = useStore();
  const [showAddNewSshKeyForm, setShowAddNewSshKeyForm] = React.useState<boolean>(false);

  const isPlanItemVariation = props.listItemVariation === 'plan';
  const dropdownPosition = props.dropdownPosition || 'top';

  const sshKeys = React.useMemo(() => {
    return sshKeysStore.findByIds(props.sshKeyIds);
  }, [props.sshKeyIds, sshKeysStore]);

  const handleClickAddNewSshKey = () => {
    setShowAddNewSshKeyForm(true);
  };

  const handleAddSshKey = (id: number) => {
    setShowAddNewSshKeyForm(false);
    props.onAddSshKey?.(id);
  };

  const handleClickAddExistSshKey = () => {
    modals.open(ModalType.SELECT_SSH_KEY, {
      selectedSshKeysIds: props.sshKeyIds,
      callbacks: {
        onSelectSshKey: (data: IModalData | undefined) => {
          if (data?.sshKey?.id) {
            handleAddSshKey(data.sshKey.id);
          }
        },
      },
    });
  };

  const handleClickSshFormCancelBtn = () => {
    setShowAddNewSshKeyForm(false);
  };

  const handleSshKeyDelete = (id: number) => {
    props.onDeleteSshKey?.(id);
  };

  return (
    <>
      {dropdownPosition === 'top' && !props.hideAddNewKeyButton ? (
        <SshKeysViewerRow className="top-dropdown">
          <AddSshKeyDropdown
            onClickAddNewSshKey={handleClickAddNewSshKey}
            onClickAddExistSshKey={handleClickAddExistSshKey}
          />
        </SshKeysViewerRow>
      ) : null}

      <Box sx={{width: '100%'}}>
        {sshKeys.map((sshKey) =>
          isPlanItemVariation ? (
            <SshKeyForm key={sshKey.id} sshKey={sshKey} onClickRemoveBtn={handleSshKeyDelete} />
          ) : (
            <ServerSshKeyItem key={sshKey.id} sshKey={sshKey} onSshKeyDelete={handleSshKeyDelete} />
          ),
        )}
      </Box>

      {showAddNewSshKeyForm && (
        <SshKeysViewerRow className="no-margin">
          <SshKeyForm onAddSshKey={handleAddSshKey} onClickCancelBtn={handleClickSshFormCancelBtn} />
        </SshKeysViewerRow>
      )}

      {dropdownPosition === 'bottom' && !props.hideAddNewKeyButton ? (
        <AddSshKeyDropdown
          onClickAddNewSshKey={handleClickAddNewSshKey}
          onClickAddExistSshKey={handleClickAddExistSshKey}
        />
      ) : null}
    </>
  );
});

export default SshKeysViewer;
