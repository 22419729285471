import {Button, IconButton, styled, useTheme} from '@mui/material';

import cn from 'classnames';
import {t} from 'i18next';
import {observer} from 'mobx-react';
import {ReactComponent as PlusIcon} from '../../assets/icons/plus.svg';
import {ReactComponent as SidebarCollapsed} from '../../assets/icons/sidebar-collapsed.svg';
import {ReactComponent as SidebarFixedIcon} from '../../assets/icons/sidebar-fixed.svg';
import {ReactComponent as LogoIcon} from '../../assets/image-icons/logo.svg';
import {useStore} from '../../stores/AppStore';

const CreateServerButton = styled(Button)(() => ({
  '&': {
    justifyContent: 'space-between',
    minWidth: 0,
  },
  '&.collapsed': {
    justifyContent: 'center',
    '& .MuiButton-icon': {
      margin: 0,
    },
  },
}));

interface IProps {
  onCreateServer?(): void;
}

export const PanelSidebarHead: React.FC<IProps> = observer((props) => {
  const theme = useTheme();
  const {
    layoutStore: {sidebar},
  } = useStore();

  const handleToggleMode = () => {
    sidebar.toggleCurrentMode();
  };

  return (
    <>
      <div className="sidebar-head">
        <LogoIcon />
        <IconButton size="small" className="sidebar-head__toggle" onClick={handleToggleMode} disableRipple>
          {sidebar.isStaticMode ? <SidebarCollapsed /> : <SidebarFixedIcon />}
        </IconButton>
      </div>
      <div>
        <CreateServerButton
          className={cn({collapsed: sidebar.collapsed})}
          variant="contained"
          fullWidth
          endIcon={<PlusIcon style={{fill: theme.palette.body.tint}} />}
          onClick={props.onCreateServer}
          size="large"
        >
          {sidebar.collapsed ? null : t('sidebar_create_server_button')}
        </CreateServerButton>
      </div>
    </>
  );
});

export default PanelSidebarHead;
