import {action, computed, makeObservable, observable} from 'mobx';
import {ca2types} from '../../api/proto';
import PriceFormatter from '../../utils/priceFormatter';
import DomainsStore from './DomainsStore';

export class DomainSuggestion {
  @observable id: string | null = null;
  @observable name: string | null = null;
  @observable isReady: boolean = false;
  @observable isAvailable: boolean = false;
  @observable isPremium: boolean = false;
  @observable private oldRegistrationPrice: ca2types.IAmount | null = null;
  @observable private registrationPrice: ca2types.IAmount | null = null;
  @observable private renewalPrice: ca2types.IAmount | null = null;
  @observable whoisUrl: string | null = null;
  @observable siteUrl: string | null = null;

  @observable oldRegistrationPriceFormatter: PriceFormatter;
  @observable registrationPriceFormatter: PriceFormatter;
  @observable renewalPriceFormatter: PriceFormatter;

  constructor(public raw: ca2types.IDomainSuggestion, public domainsStore: DomainsStore) {
    makeObservable(this);

    this.update(raw);

    this.oldRegistrationPriceFormatter = new PriceFormatter(raw.oldRegistrationPrice);
    this.registrationPriceFormatter = new PriceFormatter(raw.registrationPrice);
    this.renewalPriceFormatter = new PriceFormatter(raw.renewalPrice);
  }

  @computed get existWithSearchText() {
    return this.domainsStore.search.searchText === this.name;
  }

  @computed get hasBalanceToOrderDomain(): boolean {
    return this.registrationPriceFormatter.isLessOrEqualPoints(
      this.domainsStore.app.billingStore.balanceFormatter.points,
    );
  }

  @action update = (raw: ca2types.IDomainSuggestion) => {
    Object.assign(this, raw);

    this.oldRegistrationPriceFormatter = new PriceFormatter(raw.oldRegistrationPrice);
    this.registrationPriceFormatter = new PriceFormatter(raw.registrationPrice);
    this.renewalPriceFormatter = new PriceFormatter(raw.renewalPrice);
  };
}

export default DomainSuggestion;
