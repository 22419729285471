import {Box, Tooltip, Typography, styled, useTheme} from '@mui/material';
import cn from 'classnames';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as BaseInfoIcon} from '../../../assets/icons/info.svg';
import {calculateSecondsDifference, currentTimestamp, formatTimestamp} from '../../../utils/format/format';
import CountdownTimer from '../../CountdownTimer';

const Container = styled(Box)(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid ${theme.palette.border.primary}`,
  borderRadius: '8px',
  padding: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

const TimerBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

const InfoIcon = styled(BaseInfoIcon)(({theme}) => ({
  fill: theme.palette.backgroundSecondary,
  rect: {
    fill: theme.palette.body.primary,
  },
  '&.tooltipOpen': {
    fill: theme.palette.backgroundQuaternary,
  },
}));

interface IProps {
  validUntilTimestamp: number;
  onTimerEnd?(): void;
}

export const PaymentRateTimer: React.FC<IProps> = ({validUntilTimestamp, onTimerEnd}) => {
  const theme = useTheme();
  const {t} = useTranslation();

  const [tooltipOpen, setTooltipOpen] = React.useState(false);

  const lastUpdatedDate = formatTimestamp(currentTimestamp());
  const differenceInSeconds = calculateSecondsDifference(validUntilTimestamp);

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  return (
    <Container>
      <Typography variant="body3" sx={{color: theme.palette.body.placeholder}} gutterBottom>
        {t('component_payment_viewer_payment_details_lats_updated', {date: lastUpdatedDate})}
      </Typography>

      <Typography variant="body3">{t('component_payment_viewer_rate_valid_for')}</Typography>
      <TimerBox>
        <Typography variant="h3" sx={{width: '70px'}}>
          <CountdownTimer differenceInSeconds={differenceInSeconds} onTimerEnd={onTimerEnd} />
        </Typography>

        <Tooltip
          onOpen={handleTooltipOpen}
          onClose={handleTooltipClose}
          title={t('component_payment_viewer_rate_valid_until_timer_expires')}
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -14],
                  },
                },
              ],
            },
          }}
        >
          <InfoIcon className={cn({tooltipOpen})} />
        </Tooltip>
      </TimerBox>
    </Container>
  );
};

export default PaymentRateTimer;
