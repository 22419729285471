import {action, computed, makeAutoObservable, observable} from 'mobx';
import browserStorage from '../../utils/browserStorage';

enum SIDEBAR_MODE {
  FLEXIBLE = 'flexible',
  STATIC = 'static',
}

const SIDEBAR_VARIANT_STORAGE_KEY = 'sidebar_mode';

export class SidebarStore {
  constructor() {
    makeAutoObservable(this);
  }

  @observable private _isHover = false;
  @observable isStaticMode = false;

  @action public setIsHover = (isHover: boolean) => {
    this._isHover = isHover;
  };

  @computed get collapsed() {
    if (this.isStaticMode) {
      return false;
    }

    return !this._isHover;
  }

  @action private reset_ = () => {
    this._isHover = false;
    this.isStaticMode = false;
  };

  @action private setCurrentMode_ = (mode: SIDEBAR_MODE) => {
    const staticMode = mode !== SIDEBAR_MODE.FLEXIBLE;

    if (this.isStaticMode === staticMode) {
      return;
    }

    this.isStaticMode = staticMode;

    try {
      browserStorage.setItem(
        SIDEBAR_VARIANT_STORAGE_KEY,
        mode !== SIDEBAR_MODE.FLEXIBLE ? SIDEBAR_MODE.STATIC : SIDEBAR_MODE.FLEXIBLE,
      );
    } catch (e) {
      console.warn(e);
    }
  };

  public init = () => {
    this.reset_();

    try {
      const mode =
        browserStorage.getItem(SIDEBAR_VARIANT_STORAGE_KEY) !== 'flexible'
          ? SIDEBAR_MODE.STATIC
          : SIDEBAR_MODE.FLEXIBLE;

      this.setCurrentMode_(mode);
    } catch (e) {
      console.warn(e);
    }
  };

  public toggleCurrentMode = () => {
    this.setCurrentMode_(this.isStaticMode ? SIDEBAR_MODE.FLEXIBLE : SIDEBAR_MODE.STATIC);
  };
}

export default SidebarStore;
