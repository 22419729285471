import {InputLabel, useTheme} from '@mui/material';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import SshKeysViewer from '../../../components/SshKeysViewer';
import {TextFieldProps} from '../../../components/UI/TextField';

interface IProps {
  sshKeyIds: number[];
  onSetSshKeys(sshKeys: number[]);
  rootPasswordFieldProps?: TextFieldProps;
}

const AuthMethodField: React.FC<React.PropsWithChildren<IProps>> = observer((props) => {
  const theme = useTheme();
  const {t} = useTranslation();

  const handleAddSshKey = (sshKeyId: number) => {
    props?.onSetSshKeys([...props.sshKeyIds, sshKeyId]);
  };

  const handleClickSshFormRemoveBtn = (id: number) => {
    const filtered = props.sshKeyIds.filter((keyId) => keyId !== id);
    props?.onSetSshKeys(filtered);
  };

  return (
    <div className="auth-method-field">
      <InputLabel shrink sx={{marginBottom: theme.spacing(2)}}>
        {t('create_server_setup_plan_auth_method_auth_method_label')}
      </InputLabel>

      <div className="auth-method-field__body">
        <SshKeysViewer
          listItemVariation="plan"
          sshKeyIds={props.sshKeyIds}
          onAddSshKey={handleAddSshKey}
          onDeleteSshKey={handleClickSshFormRemoveBtn}
        />
        <div className="auth-method-field__row">{props.children}</div>
      </div>
    </div>
  );
});

export default AuthMethodField;
