import {Box} from '@mui/material';
import {observer} from 'mobx-react';
import React from 'react';
import {getApiUpTopUpBalanceError} from '../../api/getApiError';
import {ca2billing} from '../../api/proto';
import PageContentLayout from '../../layouts/PageContentLayout';
import {useStore} from '../../stores/AppStore';
import DomainSuggestion from '../../stores/Domain/DomainSuggestion';
import {Plan} from '../../stores/Plan';
import getQueryStringParam from '../../utils/getQueryStringParam';
import AwaitingPaymentForm from './AwaitingPaymentForm';
import PaymentConfirmedForm from './PaymentConfirmedForm';
import PaymentDetailsForm from './PaymentDetailsForm';
import PaymentStepsBar from './PaymentStepsBar';
import PayWithoutTopUpForm from './PayWithoutTopUpForm';
import TopUpBalanceForm from './TopUpBalanceForm';

export enum PaymentStep {
  PAY_WITHOUT_TOP_UP,
  TOP_UP_BALANCE,
  PAYMENT_DETAILS,
  AWAITING_PAYMENT,
  PAYMENT_CONFIRMED,
}

export type TopUpBalanceRequestData = {
  amount: number;
  currencyCode: string;
};

export type OrderInstance = Plan | DomainSuggestion;

const qsStep: string = getQueryStringParam('paymentStep');
const debug: string = getQueryStringParam('debug');

const getInitialStep = (orderInstance?: OrderInstance) => {
  if (debug && qsStep) {
    return parseFloat(qsStep);
  }

  if (orderInstance instanceof Plan) {
    return orderInstance.hasBalanceToPayByMonth ? PaymentStep.PAY_WITHOUT_TOP_UP : PaymentStep.TOP_UP_BALANCE;
  } else if (orderInstance instanceof DomainSuggestion) {
    return orderInstance.hasBalanceToOrderDomain ? PaymentStep.PAY_WITHOUT_TOP_UP : PaymentStep.TOP_UP_BALANCE;
  }

  return PaymentStep.TOP_UP_BALANCE;
};

interface IProps {
  orderInstance?: OrderInstance;
  onCancelPaymentOrder?(): void;
  onClickPayButton?(): Promise<void>;
}

export const PaymentViewer: React.FC<IProps> = observer((props) => {
  const {notification, billingStore} = useStore();
  const [currentStep, setCurrentStep] = React.useState<PaymentStep>(getInitialStep(props.orderInstance));
  const [topUpBalanceResponse, setTopUpBalanceResponse] = React.useState<ca2billing.ITopUpBalanceResponse | null>(null);
  const [topUpBalanceRequestData, setTopUpBalanceRequestData] = React.useState<TopUpBalanceRequestData | null>(null);

  const switchToStep = (step: PaymentStep) => {
    setCurrentStep(step);
  };

  const switchToTopUpBalance = () => {
    switchToStep(PaymentStep.TOP_UP_BALANCE);
  };

  const switchToPaymentDetails = (topUpBalanceResponse: ca2billing.ITopUpBalanceResponse) => {
    setTopUpBalanceResponse(topUpBalanceResponse);
    switchToStep(PaymentStep.PAYMENT_DETAILS);
  };

  const handleCancelPaymentOrder = () => {
    setTopUpBalanceResponse(null);
    props.onCancelPaymentOrder?.();
  };

  const switchToAwaitingPayment = () => {
    switchToStep(PaymentStep.AWAITING_PAYMENT);
  };

  const switchToPaymentConfirmed = () => {
    switchToStep(PaymentStep.PAYMENT_CONFIRMED);
  };

  const topUpBalance = async ({currencyCode, amount}: TopUpBalanceRequestData) => {
    const {res, error} = await billingStore.topUpBalance({amountUsd: amount, currencyCode});

    if (error) {
      notification.error(error.message);
    }

    if (res) {
      const {errors} = res;

      if (errors?.length) {
        errors.forEach((error) => {
          const errorMessage = getApiUpTopUpBalanceError(error);
          notification.error(errorMessage);
        });

        return;
      }

      switchToPaymentDetails(res);
    }
  };

  const handleSubmitTopUpBalanceForm = async (data: TopUpBalanceRequestData) => {
    setTopUpBalanceRequestData(data);
    await topUpBalance(data);
  };

  const handleRefreshRate = async () => {
    if (topUpBalanceRequestData) {
      await topUpBalance(topUpBalanceRequestData);
    }
  };

  const renderStepComponent = () => {
    switch (currentStep) {
      case PaymentStep.PAY_WITHOUT_TOP_UP:
        return (
          <PayWithoutTopUpForm
            orderInstance={props.orderInstance}
            onSwitchTopUpBalance={switchToTopUpBalance}
            onClickPayButton={props.onClickPayButton}
          />
        );
      case PaymentStep.TOP_UP_BALANCE:
        return (
          <TopUpBalanceForm
            orderInstance={props.orderInstance}
            onClickPayButton={props.onClickPayButton}
            onSubmit={handleSubmitTopUpBalanceForm}
          />
        );
      case PaymentStep.PAYMENT_DETAILS:
        return topUpBalanceResponse ? (
          <PaymentDetailsForm
            topUpBalanceResponse={topUpBalanceResponse}
            onRefreshRate={handleRefreshRate}
            onCancelPaymentOrder={handleCancelPaymentOrder}
            onSwitchToAwaitingPayment={switchToAwaitingPayment}
          />
        ) : null;
      case PaymentStep.AWAITING_PAYMENT:
        return topUpBalanceResponse ? (
          <AwaitingPaymentForm
            topUpBalanceResponse={topUpBalanceResponse}
            onSwitchToPaymentConfirmed={switchToPaymentConfirmed}
          />
        ) : null;
      case PaymentStep.PAYMENT_CONFIRMED:
        return topUpBalanceResponse ? (
          <PaymentConfirmedForm
            orderInstance={props.orderInstance}
            topUpBalanceResponse={topUpBalanceResponse}
            onClickPayButton={props.onClickPayButton}
          />
        ) : null;
    }
  };

  return (
    <PageContentLayout
      sx={(theme) => ({
        padding: theme.spacing(4),
      })}
    >
      <Box sx={{margin: '0 auto', maxWidth: 400}}>
        <PaymentStepsBar currentStep={currentStep} />

        {renderStepComponent()}
      </Box>
    </PageContentLayout>
  );
});

export default PaymentViewer;
