import React from 'react';
import {ModalType} from '../stores/ModalsStore';
import {IModalItem} from '../stores/ModalsStore/ModalsStore';
import ModalAddSshKey from './ModalAddSshKey';
import ModalConfirm from './ModalConfirm';
import ModalDeleteSshKey from './ModalDeleteSshKey';
import ModalEditSshKey from './ModalEditSshKey';
import ModalSelectSshKey from './ModalSelectSshKey';
import ModalTldFilter from './ModalTldFilter';

interface IProps {
  modal: IModalItem;
}

const ModalWrapper: React.FC<IProps> = (props) => {
  switch (props.modal.type) {
    case ModalType.CONFIRM:
      return <ModalConfirm />;
    case ModalType.SELECT_SSH_KEY:
      return <ModalSelectSshKey />;
    case ModalType.TLD_FILTER:
      return <ModalTldFilter />;
    case ModalType.ADD_SSH_KEY:
      return <ModalAddSshKey />;
    case ModalType.EDIT_SSH_KEY:
      return <ModalEditSshKey />;
    case ModalType.DELETE_SSH_KEY:
      return <ModalDeleteSshKey />;
    default:
      return null;
  }
};

export default ModalWrapper;
