import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {useStore} from '../stores/AppStore';

const AuthorizedLayoutWrapper: React.FC<React.PropsWithChildren> = observer(({children}) => {
  const {layoutStore} = useStore();

  return (
    <div
      className={cn('authorized-layout-wrapper', {
        sidebarStatic: layoutStore.sidebar.isStaticMode,
        sidebarCollapsed: layoutStore.sidebar.collapsed,
      })}
    >
      {children}
    </div>
  );
});

export default AuthorizedLayoutWrapper;
