import {LoadingButton} from '@mui/lab';
import {Button, DialogActions, DialogContent} from '@mui/material';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {getApiDeleteSshKeyError} from '../api/getApiError';
import Typography from '../components/UI/Typography';
import {useStore} from '../stores/AppStore';
import {ModalType} from '../stores/ModalsStore';
import BaseModal from './BaseModal';

const ModalDeleteSshKey: React.FC = observer(() => {
  const {modals, sshKeysStore, notification} = useStore();
  const {t} = useTranslation();

  const [loading, setLoading] = React.useState(false);

  const modalState = modals.getModalState(ModalType.DELETE_SSH_KEY);

  const handleClose = () => {
    modals.close(ModalType.DELETE_SSH_KEY);
  };

  const handleSubmit = async () => {
    if (!modalState.data?.sshKey?.id) {
      return;
    }

    setLoading(true);

    const {res, error} = await sshKeysStore.deleteSshKey({id: modalState.data.sshKey.id});

    if (error) {
      notification.error(error.message);
    }

    if (res) {
      const {errors} = res;

      if (errors?.length) {
        errors.forEach((error) => {
          const errorMessage = getApiDeleteSshKeyError(error);
          notification.error(errorMessage);
        });

        setLoading(false);
        return;
      }

      notification.success(t('modal_delete_ssh_key_success_notification'));
      modals.close(modalState.modalItem?.type);
    }

    setLoading(false);
  };

  return (
    <BaseModal maxWidth="sm" fullWidth type={ModalType.DELETE_SSH_KEY} title={t('modal_delete_ssh_key_title')}>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          {t('modal_delete_ssh_key_subtitle')}
        </Typography>
        <Typography variant="body1">{t('modal_delete_ssh_key_description')}</Typography>
      </DialogContent>

      <DialogActions>
        <Button size="large" variant="outlined" onClick={handleClose}>
          {t('action_button_cancel')}
        </Button>
        <LoadingButton size="large" variant="contained" color="error" onClick={handleSubmit} loading={loading}>
          {t('action_button_delete')}
        </LoadingButton>
      </DialogActions>
    </BaseModal>
  );
});

export default ModalDeleteSshKey;
